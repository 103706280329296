import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {
  BaseMessage,
  Chat,
  ChatMessageWithAssistant,
  ChatMessageWithClient,
  Client,
  ModelTag,
  PayloadMessage,
} from '../../types';
import { checkResponse, fetchWithAuth } from '../../util';
import {
  delClient,
  selectCurrentClient,
  setClient,
  setClients,
} from '../client/clientSlice';
import { AppThunk } from '../store';
import {
  AssistantMessageDto,
  AssistantMessageScoreDto,
  ChatWithMessagesDto,
  ClientMessageDto,
  QueryActorReqDto,
  QueryAssistantReqDto,
} from './chatDto';
import {
  clearMessagesWithAssistant,
  clearMessagesWithClient,
  createChat,
  delChat,
  pushAssistantMessage,
  pushClientMessage,
  pushLetsTalkAssistantMessage,
  pushLetsTalkUserToAssistantMessage,
  pushUserToAssistantMessage,
  pushUserToClientMessage,
  selectCurrentChat,
  selectCurrentChatInput,
  selectLetsTalkInputWithAssistant,
  selectLetsTalkMessagesWithAssistant,
  selectLetsTalkMessagesWithClient,
  selectMessagesWithAssistant,
  selectMessagesWithClient,
  setAssistantMessageScores,
  setChat,
  setChats,
  setInputWithAssistant,
  setLetsTalkInputWithAssistant,
} from './chatSlice';
import {
  selectActorConfig,
  selectAssistantConfig,
} from '../settings/settingsSlice';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;
const ACTOR_URL = new URL('chat/actor', BASE_URL);
const ASSISTANT_URL = new URL('chat/assistant', BASE_URL);
const CHATS_URL = new URL('chat', BASE_URL);
const MESSAGE_SCORE_URL = new URL('message/assistant/score', BASE_URL);

const BREAK_REGEX = /\s*<break>\s*/;

const TOAST_NO_MESSAGES = 'There are no messages yet!';
const TOAST_NO_INPUT = 'Your message input is needed!';
const TOAST_ARCHIVED = 'Conversation archived!';

const LABEL_ASSISTANT = 'Affected person';
const LABEL_USER = 'Volunteer';

function sleep(ms: number) {
  return new Promise((r) => setTimeout(r, ms));
}

/**
 * Serializes messages delimited by new lines.
 *
 * @param messages Messages to serialize.
 * @param labels Optional assistant and user role labels.
 * @returns Serialized string.
 */
export function serializeMessages(
  messages: BaseMessage[],
  labels?: { assistant?: string; user?: string }
) {
  return messages
    .map(
      (m) =>
        `${
          m.role === 'assistant'
            ? labels?.assistant || LABEL_ASSISTANT
            : labels?.user || LABEL_USER
        }: ${m.content}`
    )
    .join('\n\n');
}

/**
 * Reads the next chunk from the given reader.
 *
 * @param reader Reader to read from.
 * @returns `{ done: boolean; chunk: string }`
 *
 * `done`: whether the reader has been exhausted;
 * `chunk`: decoded string.
 */
async function readNext(reader: ReadableStreamDefaultReader<Uint8Array>) {
  const { done, value } = await reader.read();
  const chunk = new TextDecoder().decode(value);
  return { done, chunk };
}

/**
 * Streams string chunks from the given reader until exhaustion.
 *
 * @param reader Reader to stream from.
 */
async function* streamAnswer(
  reader: ReadableStreamDefaultReader<Uint8Array>
): AsyncGenerator<string, void, void> {
  let done = false;
  let chunk = '';
  while (!done) {
    ({ done, chunk } = await readNext(reader));
    yield chunk;
  }
}

/**
 * Separates possibly concatenated JSON object chunks into individual chunks.
 * e.g. '{"answer": "hello"}{"answer": "world"}'
 * -> '{"answer": "hello"}', '{"answer": "world"}'
 *
 * @param chunks Possibly multiple concatenated chunks.
 * @returns List of chunks.
 */
function splitChunks(chunks: string) {
  return chunks
    .split(/\}\s*\{/)
    .join('}{}{')
    .split('{}')
    .filter((c) => !!c.trim());
}

/**
 * Parses a JSON string into an object, if parseable.
 * @param chunk JSON string.
 * @returns `Object` or `undefined`.
 */
function parseChunk(chunk: string) {
  try {
    return JSON.parse(chunk);
  } catch (e) {
    console.error(e);
  }
}

const querySelfPlay = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const client = selectCurrentClient(state);
  const messagesWithClient = selectMessagesWithClient(state);
  const messagesWithAssistant = selectMessagesWithAssistant(state);
  if (!client) {
    return;
  }
  const incomingMessages = messagesWithClient.filter(
    (m) => m.role === 'assistant'
  );
  const n = incomingMessages.length;
  if (n < 1) {
    return;
  }
  const content = incomingMessages[n - 1].content;

  dispatch(
    pushUserToAssistantMessage({
      messageId: uuidv4(),
      userId: client.userId,
      payload: content,
      promptType: 'generate_response',
    })
  );
  dispatch(
    queryDialogueAssistant(
      {
        prompt_type: 'generate_response',
        content,
        history_assistant: messagesWithAssistant,
        history_client: messagesWithClient,
        persist: client.userType === 'client',
        chat_id: client.userId,
      },
      client.userId
    )
  );
};

export const queryActor =
  (
    query: QueryActorReqDto,
    hasTyping: boolean = true,
    isSelfPlay: boolean = false
  ): AppThunk =>
  async (dispatch, getState) => {
    const config = selectActorConfig(getState());
    const res = await checkResponse(
      fetchWithAuth(ACTOR_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...query,
          llm_tag: config.modelTag,
        }),
      }),
      { toastOnApiError: true, toastOnFetchError: true }
    );
    const reader = res?.body?.getReader();
    if (!reader) {
      return;
    }

    let messagePayload: PayloadMessage | null = null;
    for await (const chunks of streamAnswer(reader)) {
      for (const chunk of splitChunks(chunks)) {
        const data = parseChunk(chunk);
        if (data && 'actor' in data && 'answer' in data) {
          messagePayload = messagePayload || {
            messageId: uuidv4(),
            userId: data['actor'],
            payload: '',
          };
          if (messagePayload.userId !== data['actor']) {
            // dispatch userId-mismatched payload
            dispatch(pushClientMessage(messagePayload));
            messagePayload = {
              messageId: uuidv4(),
              userId: data['actor'],
              payload: '',
            };
          }

          const { payload, ...others } = messagePayload;
          const parts = (payload + data['answer']).split(BREAK_REGEX);
          // dispatch all but the last part
          for (const part of parts.slice(0, parts.length - 1)) {
            const messageId = uuidv4();
            // empty payload creates loading indicator
            dispatch(pushClientMessage({ ...others, messageId, payload: '' }));
            // random 1-2s sleep to simulate typing
            if (hasTyping) {
              await sleep(Math.random() * 1000 + 1000);
            }
            dispatch(
              pushClientMessage({ ...others, messageId, payload: part })
            );
          }
          messagePayload.messageId = uuidv4();
          messagePayload.payload = parts[parts.length - 1];
        }
      }
    }
    // dispatch last payload, if present
    if (messagePayload) {
      messagePayload.messageId = uuidv4();
      // empty payload creates loading indicator
      dispatch(pushClientMessage({ ...messagePayload, payload: '' }));
      // random 1-2s sleep to simulate typing
      if (hasTyping) {
        await sleep(Math.random() * 1000 + 1000);
      }
      dispatch(pushClientMessage(messagePayload));
    }

    if (isSelfPlay) {
      dispatch(querySelfPlay());
    }
  };

async function queryAssistant(
  query: QueryAssistantReqDto,
  modelTag?: ModelTag
) {
  const res = await checkResponse(
    fetchWithAuth(ASSISTANT_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...query, llm_tag: modelTag }),
    }),
    { toastOnApiError: true, toastOnFetchError: true }
  );
  return res?.body?.getReader();
}

export const queryDialogueAssistant =
  (query: QueryAssistantReqDto, selfPlayActor?: string): AppThunk =>
  async (dispatch, getState) => {
    const modelTag = selectAssistantConfig(getState()).modelTag;
    const reader = await queryAssistant(query, modelTag);
    if (!reader) {
      return;
    }
    const timestamp = new Date().toISOString();
    const selfPlayPayload: PayloadMessage = {
      payload: '',
      userId: selfPlayActor || '',
      messageId: uuidv4(),
    };
    for await (const chunks of streamAnswer(reader)) {
      for (const chunk of splitChunks(chunks)) {
        const data = parseChunk(chunk);
        if (
          data &&
          'chat_id' in data &&
          'message_id' in data &&
          'answer' in data
        ) {
          selfPlayPayload.payload += data['answer'];
          dispatch(
            pushAssistantMessage({
              userId: data['chat_id'],
              payload: data['answer'],
              messageId: data['message_id'],
              modelTag,
              timestamp,
            })
          );
        }
      }
    }

    if (selfPlayActor) {
      dispatch(pushUserToClientMessage(selfPlayPayload));
    }
  };

export const queryLetsTalkAssistant =
  (query: QueryAssistantReqDto): AppThunk =>
  async (dispatch, getState) => {
    const config = selectAssistantConfig(getState());
    const reader = await queryAssistant(query, config.modelTag);
    if (!reader) {
      return;
    }
    const timestamp = new Date().toISOString();
    for await (const chunks of streamAnswer(reader)) {
      for (const chunk of splitChunks(chunks)) {
        const data = parseChunk(chunk);
        if (data && 'message_id' in data && 'answer' in data) {
          dispatch(
            pushLetsTalkAssistantMessage({
              userId: '', // ignored
              messageId: data['message_id'],
              payload: data['answer'],
              timestamp,
            })
          );
        }
      }
    }
  };

export const directChat =
  (content: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const client = selectCurrentClient(state);
    const messagesWithAssistant = selectMessagesWithAssistant(state);
    const messagesWithClient = selectMessagesWithClient(state);

    if (!client) {
      return;
    }
    dispatch(
      queryDialogueAssistant({
        prompt_type: 'direct',
        content,
        history_assistant: messagesWithAssistant,
        history_client: messagesWithClient,
        persist: client.userType === 'client',
        chat_id: client.userId,
      })
    );
  };

export const generateResponse = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const client = selectCurrentClient(state);
  const messagesWithClient = selectMessagesWithClient(state);
  if (!client) {
    return;
  }
  const incomingMessages = messagesWithClient.filter(
    (m) => m.role === 'assistant'
  );
  const n = incomingMessages.length;
  if (n === 0) {
    toast.warn(TOAST_NO_MESSAGES);
    return;
  }
  const content = incomingMessages[n - 1].content.trim();

  dispatch(
    pushUserToAssistantMessage({
      messageId: uuidv4(),
      userId: client.userId,
      payload: content,
      promptType: 'generate_response',
    })
  );
  dispatch(
    queryDialogueAssistant({
      prompt_type: 'generate_response',
      content,
      history_client: messagesWithClient,
      persist: client.userType === 'client',
      chat_id: client.userId,
    })
  );
};

export const generateResource = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const client = selectCurrentClient(state);
  const messagesWithClient = selectMessagesWithClient(state);
  if (!client) {
    return;
  }
  const incomingMessages = messagesWithClient.filter(
    (m) => m.role === 'assistant'
  );
  const n = incomingMessages.length;
  if (n === 0) {
    toast.warn(TOAST_NO_MESSAGES);
    return;
  }
  const content = incomingMessages[n - 1].content;

  dispatch(
    pushUserToAssistantMessage({
      messageId: uuidv4(),
      userId: client.userId,
      payload: content,
      promptType: 'recommend_resource',
    })
  );
  dispatch(
    queryDialogueAssistant({
      prompt_type: 'recommend_resource',
      content,
      history_client: messagesWithClient,
      persist: client.userType === 'client',
      chat_id: client.userId,
    })
  );
};

export const analyseClientHistory =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const client = selectCurrentClient(state);
    const messagesWithClient = selectMessagesWithClient(state);
    if (!client) {
      return;
    }
    const n = messagesWithClient.length;
    if (n === 0) {
      toast.warn(TOAST_NO_MESSAGES);
      return;
    }
    const content = `${n} messages`;
    dispatch(
      pushUserToAssistantMessage({
        messageId: uuidv4(),
        userId: client.userId,
        payload: content,
        promptType: 'generate_feedback',
      })
    );
    dispatch(
      queryDialogueAssistant({
        prompt_type: 'generate_feedback',
        content,
        history_client: messagesWithClient,
        persist: client.userType === 'client',
        chat_id: client.userId,
      })
    );
  };

export const summariseClientHistory =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const client = selectCurrentClient(state);
    const messagesWithClient = selectMessagesWithClient(state);
    if (!client) {
      return;
    }
    const n = messagesWithClient.length;
    if (n === 0) {
      toast.warn(TOAST_NO_MESSAGES);
      return;
    }
    const content = `${n} messages`;
    dispatch(
      pushUserToAssistantMessage({
        messageId: uuidv4(),
        userId: client.userId,
        payload: content,
        promptType: 'summarize',
      })
    );
    dispatch(
      queryDialogueAssistant({
        prompt_type: 'summarize',
        content,
        history_client: messagesWithClient,
        persist: client.userType === 'client',
        chat_id: client.userId,
      })
    );
  };

export const rewriteEmpathetically =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const client = selectCurrentClient(state);
    const chat = selectCurrentChat(state);
    const chatInput = selectCurrentChatInput(state);
    const messagesWithClient = selectMessagesWithClient(state);
    if (!client || !chat) {
      return;
    }
    const content = chatInput?.inputWithAssistant.trim();
    if (!content) {
      toast.warn(TOAST_NO_INPUT);
      return;
    }

    dispatch(
      pushUserToAssistantMessage({
        messageId: uuidv4(),
        userId: client.userId,
        payload: content,
        promptType: 'empathetic_rewrite',
      })
    );
    dispatch(setInputWithAssistant({ userId: client.userId, payload: '' }));
    dispatch(
      queryDialogueAssistant({
        prompt_type: 'empathetic_rewrite',
        content,
        history_client: messagesWithClient,
        persist: client.userType === 'client',
        chat_id: client.userId,
      })
    );
  };

function mapDtoToMessageAssistant(
  dto: AssistantMessageDto
): ChatMessageWithAssistant {
  return {
    messageId: dto.id,
    role: dto.is_user ? 'user' : 'assistant',
    content: dto.content,
    createdAt: dto.created_at,
    modelTag: dto.model_tag,
    promptType: dto.prompt_type,
  };
}

function mapDtoToMessageClient(dto: ClientMessageDto): ChatMessageWithClient {
  return {
    messageId: dto.id,
    role: dto.is_user ? 'user' : 'assistant',
    content: dto.content,
    createdAt: dto.created_at,
  };
}

function mapDtoToClient(dto: ChatWithMessagesDto): Client {
  return {
    userId: dto.chat_id,
    userType: 'client',
    username: dto.username,
    friendlyName: dto.first_name,
    isArchived: dto.archived,
  };
}

function mapDtoToChat(dto: ChatWithMessagesDto): Chat {
  const chat = createChat(dto.chat_id);
  for (const message of dto.assistant_messages?.map(mapDtoToMessageAssistant) ||
    []) {
    chat.messagesWithAssistant[message.messageId] = message;
  }
  for (const message of dto.client_messages?.map(mapDtoToMessageClient) || []) {
    chat.messagesWithClient[message.messageId] = message;
  }
  return chat;
}

export const listChats = (): AppThunk => async (dispatch) => {
  const res = await checkResponse(fetchWithAuth(CHATS_URL), {
    toastOnApiError: true,
    toastOnFetchError: true,
  });
  if (!res) {
    return;
  }
  const data: ChatWithMessagesDto[] = await res.json();
  dispatch(setClients(data.map(mapDtoToClient)));
  dispatch(setChats(data.map(mapDtoToChat)));
};

export const archiveChat =
  (chatId: string): AppThunk =>
  async (dispatch) => {
    const archiveUrl = new URL(`chat/${chatId}/archive`, BASE_URL);
    const res = await checkResponse(
      fetchWithAuth(archiveUrl, { method: 'POST' })
    );
    if (res) {
      const data: ChatWithMessagesDto = await res.json();
      dispatch(delClient(chatId));
      dispatch(setClient(mapDtoToClient(data)));
      dispatch(delChat(chatId));
      dispatch(setChat(mapDtoToChat(data)));
      toast.success(TOAST_ARCHIVED);
    }
  };

export const deleteChatWithAssistant =
  (chatId: string): AppThunk =>
  async (dispatch, getState) => {
    const client = selectCurrentClient(getState());
    if (!client) {
      return;
    }
    if (client.userType === 'client') {
      const deleteUrl = new URL(`chat/${chatId}/assistant`, BASE_URL);
      const res = await checkResponse(
        fetchWithAuth(deleteUrl, { method: 'DELETE' }),
        { toastOnApiError: true, toastOnFetchError: true }
      );
      if (res) {
        const data: ChatWithMessagesDto = await res.json();
        dispatch(setChat(mapDtoToChat(data)));
      }
    } else {
      dispatch(clearMessagesWithAssistant(client.userId));
    }
    toast.success('Conversation cleared!');
  };

export const deleteChatWithClient =
  (chatId: string): AppThunk =>
  async (dispatch, getState) => {
    const client = selectCurrentClient(getState());
    if (!client) {
      return;
    }
    if (client.userType === 'client') {
      const deleteUrl = new URL(`chat/${chatId}/client`, BASE_URL);
      const res = await checkResponse(
        fetchWithAuth(deleteUrl, { method: 'DELETE' }),
        { toastOnApiError: true, toastOnFetchError: true }
      );
      if (res) {
        const data: ChatWithMessagesDto = await res.json();
        dispatch(setChat(mapDtoToChat(data)));
      }
    } else {
      dispatch(clearMessagesWithClient(client.userId));
    }
    toast.success('Conversation cleared!');
  };

export const getAssistantMessageScoresThunk =
  (): AppThunk => async (dispatch) => {
    const res = await checkResponse(fetchWithAuth(MESSAGE_SCORE_URL), {
      toastOnApiError: true,
      toastOnFetchError: true,
    });
    if (!res) {
      return;
    }
    const data: AssistantMessageScoreDto[] = await res.json();
    dispatch(setAssistantMessageScores(data));
  };

export const setAssistantMessageScoreThunk =
  (dto: AssistantMessageScoreDto): AppThunk =>
  async (dispatch) => {
    const res = await checkResponse(
      fetchWithAuth(MESSAGE_SCORE_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dto),
      }),
      { toastOnApiError: true, toastOnFetchError: true }
    );
    if (res?.ok) {
      dispatch(getAssistantMessageScoresThunk());
    }
  };

export const directChatForLetsTalk =
  (content: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const messagesWithAssistant = selectLetsTalkMessagesWithAssistant(state);
    const messagesWithClient = selectLetsTalkMessagesWithClient(state);

    dispatch(
      queryLetsTalkAssistant({
        chat_id: '', // not needed
        content,
        history_assistant: messagesWithAssistant,
        history_client: messagesWithClient,
        prompt_type: 'direct',
        persist: false,
      })
    );
  };

export const generateResponseForLetsTalk =
  (isLong: boolean): AppThunk =>
  async (dispatch, getState) => {
    const messagesWithClient = selectLetsTalkMessagesWithClient(getState());

    const incomingMessages = messagesWithClient.filter(
      (m) => m.role === 'assistant'
    );
    const n = incomingMessages.length;
    if (n === 0) {
      toast.warn(TOAST_NO_MESSAGES);
      return;
    }

    const content = incomingMessages[n - 1].content;

    dispatch(
      pushLetsTalkUserToAssistantMessage({
        userId: '', // ignored
        payload: isLong
          ? '___Propose long message___'
          : '___Propose short message___',
        messageId: uuidv4(),
      })
    );
    dispatch(
      queryLetsTalkAssistant({
        chat_id: '', // not needed
        content,
        history_client: messagesWithClient,
        prompt_type: isLong ? 'generate_forum_response' : 'generate_response',
        persist: false,
      })
    );
  };

export const generateResourceForLetsTalk =
  (): AppThunk => async (dispatch, getState) => {
    const messagesWithClient = selectLetsTalkMessagesWithClient(getState());
    if (messagesWithClient.length === 0) {
      toast.warn(TOAST_NO_MESSAGES);
      return;
    }

    const content = messagesWithClient[messagesWithClient.length - 1].content;
    dispatch(
      pushLetsTalkUserToAssistantMessage({
        userId: '', // ignored
        payload: '___Recommend resources___',
        messageId: uuidv4(),
      })
    );
    dispatch(
      queryLetsTalkAssistant({
        chat_id: '', // not needed
        content,
        history_client: messagesWithClient,
        prompt_type: 'recommend_resource',
        persist: false,
      })
    );
  };

export const rewriteEmpatheticallyForLetsTalk =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const messages = selectLetsTalkMessagesWithClient(state);
    const content = selectLetsTalkInputWithAssistant(state);
    if (!content) {
      toast.warn(TOAST_NO_INPUT);
      return;
    }

    dispatch(
      pushLetsTalkUserToAssistantMessage({
        userId: '', // ignored
        messageId: uuidv4(),
        payload: `___Rewrite empathetically___\n\n${content}`,
      })
    );
    dispatch(
      queryLetsTalkAssistant({
        chat_id: '', // not needed
        content,
        history_client: messages,
        prompt_type: 'empathetic_rewrite',
        persist: false,
      })
    );
    dispatch(setLetsTalkInputWithAssistant(''));
  };
