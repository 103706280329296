import { Switch } from '@nextui-org/react';
import { SettingsFeature } from '../../types';
import { useAppDispatch } from '../../store/hooks';
import { setSettingsFeature } from '../../store/settings/settingsSlice';
import SettingsRow from './SettingsRow';

export interface SettingsToggleProps {
  feature: SettingsFeature;
}

/**
 * Settings row for toggling assistant features.
 */
export default function SettingsToggle({ feature }: SettingsToggleProps) {
  const dispatch = useAppDispatch();

  const handleEnabledChange = (isEnabled: boolean) => {
    dispatch(
      setSettingsFeature({
        ...feature,
        isEnabled,
        isAutoRun: isEnabled && feature.isAutoRun,
      })
    );
  };
  const handleAutoRunChange = (isAutoRun: boolean) => {
    dispatch(setSettingsFeature({ ...feature, isAutoRun }));
  };

  return (
    <SettingsRow header={feature.label} description={feature.description}>
      <div className="flex flex-row items-center gap-1">
        <Switch
          aria-label="is enabled"
          isSelected={feature.isEnabled}
          onValueChange={handleEnabledChange}
        />
        {feature.isAutoRunnable && (
          <Switch
            aria-label="is autorun"
            color="success"
            isDisabled={!feature.isEnabled}
            isSelected={feature.isAutoRun}
            onValueChange={handleAutoRunChange}
            thumbIcon={({ className }) => <p className={className}>A</p>}
          />
        )}
      </div>
    </SettingsRow>
  );
}
