import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tab,
  Tabs,
} from '@nextui-org/react';
import { useEffect, useMemo, useState } from 'react';
import { getAssistantPrompts } from '../../store/assistant/assistantThunks';
import { PromptType } from '../../types';
import MarkdownWrapper from '../base/MarkdownWrapper';
import { DEFAULT_FEATURE_SETTINGS } from '../../util';

export interface PromptModalProps {
  isOpen: boolean;
  onOpenChange: () => void;
}

export default function PromptModal({
  isOpen,
  onOpenChange,
}: PromptModalProps) {
  const [prompts, setPrompts] = useState<
    { promptType: PromptType; prompt: string }[]
  >([]);

  const loadPrompts = () => {
    setPrompts([]);
    getAssistantPrompts()
      .then((dtos) =>
        dtos?.map((dto) => ({
          promptType: dto.prompt_type,
          prompt: dto.prompt,
        }))
      )
      .then((newPrompts) => newPrompts && setPrompts(newPrompts));
  };

  useEffect(loadPrompts, []);

  const tabs = useMemo(
    () =>
      prompts.length ? (
        <Tabs aria-label="Prompt tabs" items={prompts} radius="full">
          {(item) => (
            <Tab
              key={item.promptType}
              title={
                DEFAULT_FEATURE_SETTINGS.filter(
                  (s) => s.key === item.promptType
                ).pop()?.label || item.promptType
              }
            >
              <Card>
                <CardBody>
                  <MarkdownWrapper>{item.prompt}</MarkdownWrapper>
                </CardBody>
              </Card>
            </Tab>
          )}
        </Tabs>
      ) : (
        <p className="italic">No prompts.</p>
      ),
    [prompts]
  );

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader>Prompt Viewer</ModalHeader>
            <ModalBody className="border-y-1">{tabs}</ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  variant="flat"
                  isLoading={!prompts.length}
                  onPress={loadPrompts}
                >
                  Reload prompts
                </Button>
                <Button color="primary" onPress={onClose}>
                  Close
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
