import {
  Accordion,
  AccordionItem,
  Listbox,
  ListboxItem,
  Selection,
} from '@nextui-org/react';
import { FaAngleRight } from 'react-icons/fa6';
import ChatListItem from './ChatListItem';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectChats } from '../../store/chat/chatSlice';
import {
  selectActiveClients,
  selectArchivedClients,
  selectCurrentClient,
  setCurrentClient,
} from '../../store/client/clientSlice';
import { Client } from '../../types';

export default function ChatListClients() {
  const dispatch = useAppDispatch();
  const chats = useAppSelector(selectChats);
  const currentClient = useAppSelector(selectCurrentClient);
  const activeClients = useAppSelector(selectActiveClients);
  const archivedClients = useAppSelector(selectArchivedClients);

  const handleSelectionChange = (keys: Selection) => {
    if (keys === 'all') {
      return;
    }
    for (const k of keys.values()) {
      const userId = k.toString();
      dispatch(setCurrentClient(userId));
    }
  };

  const ChatListClientsList = ({
    clients,
    ariaLabel,
  }: {
    clients: Client[];
    ariaLabel: string;
  }) => (
    <Listbox
      items={clients}
      itemClasses={{ base: 'rounded-medium' }}
      variant="flat"
      selectionMode="single"
      selectedKeys={currentClient?.userId ? [currentClient.userId] : []}
      onSelectionChange={handleSelectionChange}
      emptyContent={<p>No chats.</p>}
      aria-label={ariaLabel}
    >
      {(c) => (
        <ListboxItem
          key={c.userId}
          textValue={c.userId}
          selectedIcon={(props) =>
            props.isSelected && <FaAngleRight size={12} />
          }
        >
          <ChatListItem
            client={c}
            messages={chats[c.userId]?.messagesWithClient}
          />
        </ListboxItem>
      )}
    </Listbox>
  );

  return (
    <div className="flex flex-1 flex-col justify-between gap-2 mb-2">
      <ChatListClientsList
        clients={activeClients}
        ariaLabel="Active clients chat list"
      />
      <Accordion isCompact variant="splitted" className="px-0">
        <AccordionItem subtitle="Archived chats" textValue="archived chats">
          <ChatListClientsList
            clients={archivedClients}
            ariaLabel="Archived clients chat list"
          />
        </AccordionItem>
      </Accordion>
    </div>
  );
}
