import { v4 as uuidv4 } from 'uuid';
import { Button, ButtonGroup, Tooltip } from '@nextui-org/react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ChatBox from '../chat/ChatBox';
import {
  copyToClientInput,
  exportMessagesPlainText,
  pushUserToClientMessage,
  pushUserToWebsocketMessage,
  selectMessagesWithAssistant,
  selectMessagesWithClient,
} from '../../store/chat/chatSlice';
import { selectCurrentClient } from '../../store/client/clientSlice';
import { selectSettingsFeatures } from '../../store/settings/settingsSlice';
import { useEffect, useMemo, useRef, useState } from 'react';
import ChatView from '../chat/ChatView';
import {
  analyseClientHistory,
  deleteChatWithAssistant,
  generateResource,
  generateResponse,
  rewriteEmpathetically,
  summariseClientHistory,
} from '../../store/chat/chatThunks';
import { FaFileArrowDown, FaTrash } from 'react-icons/fa6';
import ButtonWithConfirmModal from '../base/ButtonWithConfirmModal';
import FeatureBar from '../feature/FeatureBar';
import FeatureItem from '../feature/FeatureItem';
import ChatInputAssistant from '../chat/input/ChatInputAssistant';
import { PromptType } from '../../types';
import { AnimatePresence } from 'framer-motion';
import { APP } from '../../util';

export default function AssistantView() {
  const dispatch = useAppDispatch();
  const features = useAppSelector(selectSettingsFeatures);
  const currentClient = useAppSelector(selectCurrentClient);
  const messagesWithAssistant = useAppSelector(selectMessagesWithAssistant);
  const messagesWithClient = useAppSelector(selectMessagesWithClient);
  const [hasRun, setHasRun] = useState(false);

  const featureFunctions = useMemo(() => {
    const functions = new Map<PromptType, () => void>();
    functions.set('generate_response', () => dispatch(generateResponse()));
    functions.set('generate_feedback', () => dispatch(analyseClientHistory()));
    functions.set('recommend_resource', () => dispatch(generateResource()));
    functions.set('summarize', () => dispatch(summariseClientHistory()));
    functions.set('empathetic_rewrite', () =>
      dispatch(rewriteEmpathetically())
    );
    return functions;
  }, [dispatch]);

  const featureFunctionsRef = useRef<any>();
  useEffect(() => {
    featureFunctionsRef.current = featureFunctions;
  }, [featureFunctions]);

  useEffect(() => {
    if (!messagesWithClient) {
      return;
    }
    const n = messagesWithClient.length;
    if (n > 0 && messagesWithClient[n - 1].isUnread) {
      setHasRun(false);
    }
  }, [messagesWithClient]);

  // handles auto-run functionality for features
  useEffect(() => {
    if (!messagesWithClient) {
      return;
    }
    if (featureFunctions !== featureFunctionsRef.current) {
      // prevents autorun from triggering twice due to this state changing
      return;
    }

    const n = messagesWithClient.length;
    if (
      hasRun ||
      n === 0 ||
      messagesWithClient[n - 1].role === 'user' ||
      !messagesWithClient[n - 1].isUnread
    ) {
      return;
    }

    setHasRun(true);
    features.forEach((feat) => {
      const fn = featureFunctions.get(feat.key);
      if (feat.isEnabled && feat.isAutoRun && fn) {
        fn();
      }
    });
  }, [dispatch, messagesWithClient, features, featureFunctions, hasRun]);

  const handleTransfer = (message: string, sendImmediately = false) => {
    if (!currentClient) {
      return;
    }
    const { userId, userType } = currentClient;
    if (sendImmediately) {
      dispatch(
        pushUserToClientMessage({
          userId,
          messageId: uuidv4(),
          payload: message,
        })
      );
      switch (userType) {
        case 'client':
          dispatch(pushUserToWebsocketMessage({ userId, payload: message }));
          break;
      }
    } else {
      dispatch(copyToClientInput({ userId, payload: message }));
    }
  };

  const handleClear = () => {
    if (currentClient?.userId) {
      dispatch(deleteChatWithAssistant(currentClient.userId));
    }
  };

  const handleExportPlainText = () => {
    // TODO: include message quoting format
    if (messagesWithAssistant) {
      exportMessagesPlainText(messagesWithAssistant, {
        messagesLabel: 'assistant',
        assistantLabel: APP.TITLE.DEFAULT,
        userLabel: 'Volunteer',
      });
    }
  };

  const hasFeaturesEnabled = useMemo(
    () => features.reduce((prev, curr) => prev || curr.isEnabled, false),
    [features]
  );

  const featuresBar = hasFeaturesEnabled && !currentClient?.isArchived && (
    <FeatureBar>
      {features
        .filter((feat) => featureFunctions.has(feat.key))
        .map(
          (feat) =>
            feat.isEnabled && (
              <FeatureItem
                key={feat.key}
                feature={feat}
                onPress={featureFunctions.get(feat.key)}
              />
            )
        )}
    </FeatureBar>
  );

  const buttonExport = (
    <Tooltip content="Export as plain text" placement="bottom">
      <Button isDisabled onPress={handleExportPlainText}>
        <FaFileArrowDown />
      </Button>
    </Tooltip>
  );

  const buttonClear = (
    <ButtonWithConfirmModal
      onPress={handleClear}
      buttonContent={<FaTrash />}
      buttonProps={{
        color: 'danger',
        isDisabled: !messagesWithAssistant?.length,
      }}
      tooltipProps={{
        content: 'Clear conversation',
        placement: 'bottom',
        color: 'danger',
      }}
      confirmLabel="Clear"
      modalHeader="Clear conversation?"
    />
  );

  const chatBox = (
    <AnimatePresence mode="wait">
      {currentClient && (
        <ChatBox
          key={currentClient.userId}
          isAssistant
          messages={messagesWithAssistant}
          onTransfer={handleTransfer}
          headerTitle={`${APP.TITLE.DEFAULT} Assistant`}
          headerElements={[
            <ButtonGroup key="chat-actions" isIconOnly variant="light">
              {buttonExport}
              {buttonClear}
            </ButtonGroup>,
          ]}
          inputField={
            <div className="relative flex flex-col items-start gap-2">
              {featuresBar}
              <ChatInputAssistant />
            </div>
          }
        />
      )}
    </AnimatePresence>
  );

  return <ChatView chatId={currentClient?.userId} chatBox={chatBox} />;
}
