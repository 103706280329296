import { Button, ButtonProps } from '@nextui-org/react';
import { FaGear } from 'react-icons/fa6';

export default function SettingsButton(props: ButtonProps) {
  return (
    <Button {...props} isIconOnly>
      <FaGear />
    </Button>
  );
}
