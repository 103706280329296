import { Button, ButtonProps } from '@nextui-org/react';
import { FaCircleInfo } from 'react-icons/fa6';

export default function AboutButton(props: ButtonProps) {
  return (
    <Button {...props} isIconOnly>
      <FaCircleInfo />
    </Button>
  );
}
