import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { Actor, ActorMap, ActorType, Client, ClientMap } from '../../types';
import { RootState } from '../store';

interface ClientState {
  actors: ActorMap;
  clients: ClientMap;
  currentClientId?: string;
}

const actorsMap: ActorMap = {};
const actorTypes: ActorType[] = ['neurotic', 'suicidal'];
const actors: Actor[] = actorTypes.map((t) => ({
  userType: 'actor',
  userId: t,
  username: t.charAt(0).toUpperCase() + t.slice(1),
}));

const initialState: ClientState = {
  actors: actors.reduce((m, actor) => {
    m[actor.userId] = actor;
    return m;
  }, actorsMap),
  clients: {},
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClients: (state, action: PayloadAction<Client[]>) => {
      const clients: ClientMap = {};
      for (const client of action.payload) {
        clients[client.userId] = client;
      }
      state.clients = clients;
    },
    setClient: (state, action: PayloadAction<Client>) => {
      state.clients[action.payload.userId] = action.payload;
    },
    delClient: (state, action: PayloadAction<string>) => {
      delete state.clients[action.payload];
    },
    setCurrentClient: (state, action: PayloadAction<string | undefined>) => {
      state.currentClientId = action.payload;
    },
    addActor: (state, action: PayloadAction<Actor>) => {
      const actor = action.payload;
      state.actors[actor.userId] = actor;
    },
    delActor: (state, action: PayloadAction<string>) => {
      delete state.actors[action.payload];
    },
  },
});

export const {
  setClients,
  setClient,
  delClient,
  setCurrentClient,
  addActor,
  delActor,
} = clientSlice.actions;

export const selectActors = (state: RootState) => state.client.actors;

export const selectClients = (state: RootState) => state.client.clients;

export const selectActiveClients = createSelector(selectClients, (clientMap) =>
  Array.from(Object.values(clientMap)).filter((c) => !c.isArchived)
);

export const selectArchivedClients = createSelector(
  selectClients,
  (clientMap) =>
    Array.from(Object.values(clientMap)).filter((c) => c.isArchived)
);

export const selectCurrentClient = (state: RootState) => {
  const clientId = state.client.currentClientId;
  if (clientId) {
    if (clientId in state.client.actors) {
      return state.client.actors[clientId];
    }
    if (clientId in state.client.clients) {
      return state.client.clients[clientId];
    }
  }
};

export const selectCurrentActor = (state: RootState) => {
  const clientId = state.client.currentClientId;
  if (clientId) {
    return state.client.actors[clientId];
  }
};

export const selectCurrentClientId = (state: RootState) =>
  state.client.currentClientId;

export default clientSlice.reducer;
