import { useMemo } from 'react';
import { ChatMessage, Client } from '../../types';
import { Avatar, Badge } from '@nextui-org/react';
import { useAppSelector } from '../../store/hooks';
import {
  riskToLabel,
  selectRiskOfChats,
} from '../../store/assistant/assistantSlice';

export interface ChatListItemProps {
  client: Client;
  messages?: { [messageId: string]: ChatMessage };
}

export default function ChatListItem({ client, messages }: ChatListItemProps) {
  const riskOfChats = useAppSelector(selectRiskOfChats);

  const unreadCount = useMemo(() => {
    let count = 0;
    if (!messages) {
      return count;
    }
    for (const messageId in messages) {
      if (messages[messageId].isUnread) {
        count += 1;
      }
    }
    return count;
  }, [messages]);

  const lastMessage = useMemo(() => {
    if (!messages) {
      return;
    }
    let message: ChatMessage | null = null;
    for (const messageId in messages) {
      if (!message) {
        message = messages[messageId];
        continue;
      }
      if (messages[messageId].createdAt.localeCompare(message.createdAt) > 0) {
        message = messages[messageId];
      }
    }
    return message?.content;
  }, [messages]);

  const displayName = useMemo(
    () => client.friendlyName || client.username || client.userId,
    [client]
  );

  const riskLevel = useMemo(
    () => riskOfChats[client.userId],
    [client, riskOfChats]
  );

  const riskColor = useMemo(() => riskToLabel(riskLevel).color, [riskLevel]);

  return (
    <div className="grid grid-rows-1 grid-cols-4 gap-1">
      <div className="col-span-1 flex justify-start items-center">
        <Badge
          content={unreadCount}
          isInvisible={unreadCount === 0}
          color="primary"
          shape="circle"
          placement="bottom-right"
          className="mb-1"
        >
          <Badge
            content=""
            shape="circle"
            variant="shadow"
            color={riskColor}
            isInvisible={!riskLevel}
            className="mt-1"
          >
            <Avatar
              size="md"
              name={displayName
                .split(/\s+/)
                .filter((s) => !!s)
                .map((s) => s[0])
                .slice(0, 2)
                .join('')}
            />
          </Badge>
        </Badge>
      </div>
      <div className="col-span-3 flex flex-col justify-center min-w-0">
        <p className="text-base font-semibold text-ellipsis overflow-hidden">
          {displayName}
        </p>
        {lastMessage && (
          <p className="text-sm text-ellipsis overflow-hidden">{lastMessage}</p>
        )}
      </div>
    </div>
  );
}
