import { Card, CardBody, Tab, Tabs } from '@nextui-org/react';
import { useState } from 'react';
import ChatListClients from './ChatListClients';
import ChatListActors from './ChatListActors';

export default function ChatList() {
  const [selectedTab, setSelectedTab] = useState<string>('');

  return (
    <Card radius="none" shadow="none" className="flex w-full">
      <CardBody className="relative px-2 py-0">
        <div className="sticky top-0 w-full z-20">
          <div className="relative my-2 w-fit max-w-full border-1 rounded-full backdrop-blur-md">
            <Tabs
              color="primary"
              radius="full"
              variant="light"
              aria-label="Chat switcher"
              selectedKey={selectedTab}
              onSelectionChange={(k) => setSelectedTab(k.toString())}
              className="opacity-100 max-w-full overflow-x-scroll"
            >
              <Tab key="clients" title="Affected Persons" />
              <Tab key="actors" title="Actors" />
            </Tabs>
          </div>
        </div>
        {selectedTab === 'clients' && <ChatListClients />}
        {selectedTab === 'actors' && <ChatListActors />}
      </CardBody>
    </Card>
  );
}
