import { Button } from '@nextui-org/react';
import { AnimatePresence, motion } from 'framer-motion';
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FaArrowDown } from 'react-icons/fa6';

export interface VerticalScrollContainerProps extends PropsWithChildren {
  topContent?: ReactNode;
  bottomContent?: ReactNode;
  bottomScrollContent?: ReactNode;
  resetSignal?: number;
  scrollToBottomSignal?: number;
}

export default function VerticalScrollContainer({
  children,
  topContent,
  bottomContent,
  bottomScrollContent,
  resetSignal,
  scrollToBottomSignal,
}: VerticalScrollContainerProps) {
  const bottom = useRef<HTMLDivElement>(null);
  const bottomSpacer = useRef<HTMLDivElement>(null);
  const [isTop, setIsTop] = useState(true);
  const [isBottom, setIsBottom] = useState(true);

  const scrollToBottom = useCallback(
    (isSmooth?: boolean) => {
      bottom.current?.scrollIntoView({
        behavior: isSmooth ? 'smooth' : 'auto',
      });
    },
    [bottom]
  );

  const scrollToBottomSmooth = useCallback(
    () => scrollToBottom(true),
    [scrollToBottom]
  );

  useEffect(() => {
    setIsTop(true);
    setIsBottom(true);
  }, [resetSignal]);

  useEffect(() => {
    scrollToBottomSmooth();
  }, [scrollToBottomSignal, scrollToBottomSmooth]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    // adapted from https://stackoverflow.com/a/49573628
    const el = e.currentTarget;
    const distToTop = Math.abs(el.scrollTop);
    const distToBottom = Math.abs(
      el.scrollHeight - (el.scrollTop + el.clientHeight)
    );
    setIsTop(distToTop <= 1);
    setIsBottom(distToBottom <= 1);
  };

  const scrollToBottomButton = (
    <AnimatePresence>
      {(!isTop || !isBottom) && (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: isBottom ? 0 : 1 }}
          exit={{ scale: 0 }}
          className="self-center mt-2"
        >
          <Button
            isIconOnly
            color="primary"
            radius="full"
            variant="shadow"
            onClick={scrollToBottomSmooth}
          >
            <FaArrowDown />
          </Button>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return (
    <div
      onScroll={handleScroll}
      className="relative flex flex-col w-full h-full overflow-y-scroll"
    >
      <div className="sticky top-0 z-10 flex flex-col">
        {topContent}
        {scrollToBottomButton}
      </div>
      <div className="relative flex flex-1 flex-col w-full">{children}</div>
      {/* div to scroll to when scroll-to-bottom is triggered */}
      <div ref={bottom} />
      {bottomScrollContent}
      {/* spacer for the sticky bottom content */}
      <div style={{ height: bottomSpacer.current?.clientHeight || 0 }} />
      <div ref={bottomSpacer} className="sticky bottom-0 z-10">
        {bottomContent}
      </div>
    </div>
  );
}
