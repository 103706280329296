import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  Chat,
  ChatInput,
  ChatInputMap,
  ChatMap,
  ChatMessage,
  ChatMessageWithAssistant,
  ChatMessageWithClient,
  MessageScoreMap,
  PayloadBase,
  PayloadMessage,
  PayloadMessageAssistant,
} from '../../types';
import { RootState } from '../store';
import { AssistantMessageScoreDto, SendClientMessageReqDto } from './chatDto';

const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL || '';
const BREAK_TOKEN = '<break>';

function messageCreatedAtComparator<T extends ChatMessage>(m1: T, m2: T) {
  return m1.createdAt.localeCompare(m2.createdAt);
}

export function createChat(chatId: string): Chat {
  return {
    chatId,
    messagesWithClient: {},
    messagesWithAssistant: {},
  };
}

export function createChatInput(): ChatInput {
  return {
    inputWithAssistant: '',
    inputWithClient: '',
  };
}

interface ChatState {
  isConnected: boolean;
  webSocket: WebSocket;
  chats: ChatMap;
  chatInputs: ChatInputMap;
  assistantMessageScores: MessageScoreMap;
  letsTalkChat: Chat;
  letsTalkInput: ChatInput;
}

const initialState: ChatState = {
  isConnected: false,
  webSocket: new WebSocket(WEBSOCKET_URL),
  chats: {},
  chatInputs: {},
  assistantMessageScores: {},
  letsTalkChat: createChat('lets-talk'),
  letsTalkInput: createChatInput(),
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    refreshWebsocket: (state) => {
      state.webSocket.close();
      state.webSocket = new WebSocket(WEBSOCKET_URL);
    },
    setConnected: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
    setChats: (state, action: PayloadAction<Chat[]>) => {
      const chats: ChatMap = {};
      for (const chat of action.payload) {
        chats[chat.chatId] = chat;
      }
      state.chats = chats;
    },
    setChat: (state, action: PayloadAction<Chat>) => {
      const chat = action.payload;
      state.chats[chat.chatId] = chat;
    },
    delChat: (state, action: PayloadAction<string>) => {
      delete state.chats[action.payload];
    },
    setInputWithClient: (state, action: PayloadAction<PayloadBase>) => {
      const { userId, payload } = action.payload;
      if (!state.chatInputs[userId]) {
        state.chatInputs[userId] = createChatInput();
      }
      state.chatInputs[userId].inputWithClient = payload;
    },
    setInputWithAssistant: (state, action: PayloadAction<PayloadBase>) => {
      const { userId, payload } = action.payload;
      if (!state.chatInputs[userId]) {
        state.chatInputs[userId] = createChatInput();
      }
      state.chatInputs[userId].inputWithAssistant = payload;
    },
    appendLetsTalkInputWithClient: (state, action: PayloadAction<string>) => {
      state.letsTalkInput.inputWithClient += action.payload;
    },
    appendLetsTalkInputWithAssistant: (
      state,
      action: PayloadAction<string>
    ) => {
      state.letsTalkInput.inputWithAssistant += action.payload;
    },
    setLetsTalkInputWithClient: (state, action: PayloadAction<string>) => {
      state.letsTalkInput.inputWithClient = action.payload;
    },
    setLetsTalkInputWithAssistant: (state, action: PayloadAction<string>) => {
      state.letsTalkInput.inputWithAssistant = action.payload;
    },
    clearMessagesWithClient: (state, action: PayloadAction<string>) => {
      const userId = action.payload;
      state.chats[userId] = state.chats[userId] || createChat(userId);
      state.chats[userId].messagesWithClient = {};
    },
    clearMessagesWithAssistant: (state, action: PayloadAction<string>) => {
      const userId = action.payload;
      state.chats[userId] = state.chats[userId] || createChat(userId);
      state.chats[userId].messagesWithAssistant = {};
    },
    clearMessagesWithLetsTalkClient: (state) => {
      state.letsTalkChat.messagesWithClient = {};
    },
    clearMessagesWithLetsTalkAssistant: (state) => {
      state.letsTalkChat.messagesWithAssistant = {};
    },
    pushClientMessage: (state, action: PayloadAction<PayloadMessage>) => {
      const { userId, payload, timestamp, messageId } = action.payload;
      state.chats[userId] = state.chats[userId] || createChat(userId);

      const messages = state.chats[userId].messagesWithClient;
      const message = messages[messageId] || {
        messageId,
        role: 'assistant',
        content: '',
        createdAt: timestamp || new Date().toISOString(),
        isUnread: true,
      };
      message.content += payload;
      messages[messageId] = message;
    },
    pushAssistantMessage: (
      state,
      action: PayloadAction<PayloadMessageAssistant>
    ) => {
      const { userId, payload, timestamp, messageId, modelTag } =
        action.payload;
      state.chats[userId] = state.chats[userId] || createChat(userId);

      const messages = state.chats[userId].messagesWithAssistant;
      const message = messages[messageId] || {
        messageId,
        role: 'assistant',
        content: '',
        createdAt: timestamp || new Date().toISOString(),
        promptType: 'direct',
        modelTag: modelTag,
      };
      message.content += payload;
      messages[messageId] = message;
    },
    pushUserToClientMessage: (state, action: PayloadAction<PayloadMessage>) => {
      const { userId, payload, timestamp, messageId } = action.payload;
      state.chats[userId] = state.chats[userId] || createChat(userId);
      state.chats[userId].messagesWithClient[messageId] = {
        messageId,
        role: 'user',
        content: payload,
        createdAt: timestamp || new Date().toISOString(),
      };
    },
    pushUserToAssistantMessage: (
      state,
      action: PayloadAction<PayloadMessageAssistant>
    ) => {
      const { userId, payload, timestamp, messageId, promptType } =
        action.payload;
      state.chats[userId] = state.chats[userId] || createChat(userId);
      state.chats[userId].messagesWithAssistant[messageId] = {
        messageId,
        role: 'user',
        content: payload,
        createdAt: timestamp || new Date().toISOString(),
        promptType: promptType || 'direct',
      };
    },
    pushUserToWebsocketMessage: (state, action: PayloadAction<PayloadBase>) => {
      const { userId, payload } = action.payload;
      const dto: SendClientMessageReqDto = {
        chat_id: userId,
        content: payload,
        is_user: true,
      };
      state.webSocket.send(JSON.stringify(dto));
    },
    pushLetsTalkClientMessage: (
      state,
      action: PayloadAction<PayloadMessage>
    ) => {
      const { payload, timestamp, messageId } = action.payload;
      const messages = state.letsTalkChat.messagesWithClient;
      const message = messages[messageId] || {
        messageId,
        role: 'assistant',
        content: '',
        createdAt: timestamp || new Date().toISOString(),
      };
      message.content += payload;
      messages[messageId] = message;
    },
    pushLetsTalkAssistantMessage: (
      state,
      action: PayloadAction<PayloadMessage>
    ) => {
      const { payload, timestamp, messageId } = action.payload;
      const messages = state.letsTalkChat.messagesWithAssistant;
      const message = messages[messageId] || {
        messageId,
        role: 'assistant',
        content: '',
        createdAt: timestamp || new Date().toISOString(),
      };
      message.content += payload;
      messages[messageId] = message;
    },
    pushLetsTalkUserToClientMessage: (
      state,
      action: PayloadAction<PayloadMessage>
    ) => {
      const { payload, timestamp, messageId } = action.payload;
      state.letsTalkChat.messagesWithClient[messageId] = {
        messageId,
        role: 'user',
        content: payload,
        createdAt: timestamp || new Date().toISOString(),
      };
    },
    pushLetsTalkUserToAssistantMessage: (
      state,
      action: PayloadAction<PayloadMessageAssistant>
    ) => {
      const { payload, timestamp, messageId, promptType } = action.payload;
      state.letsTalkChat.messagesWithAssistant[messageId] = {
        messageId,
        role: 'user',
        content: payload,
        createdAt: timestamp || new Date().toISOString(),
        promptType: promptType || 'direct',
      };
    },
    copyToClientInput: (state, action: PayloadAction<PayloadBase>) => {
      const { userId, payload } = action.payload;
      const inputs = state.chatInputs[userId] || createChatInput();
      if (inputs.inputWithClient.length > 0) {
        inputs.inputWithClient += '\n\n';
      }
      inputs.inputWithClient += payload;
      state.chatInputs[userId] = inputs;
    },
    copyToAssistantInput: (state, action: PayloadAction<PayloadBase>) => {
      const { userId, payload } = action.payload;
      const inputs = state.chatInputs[userId] || createChatInput();
      if (inputs.inputWithAssistant.length > 0) {
        inputs.inputWithAssistant += '\n\n';
      }
      inputs.inputWithAssistant += payload;
      state.chatInputs[userId] = inputs;
    },
    readAllMessagesWithClient: (state, action: PayloadAction<string>) => {
      const userId = action.payload;
      if (state.chats[userId]) {
        const messages = state.chats[userId].messagesWithClient;
        for (const messageId in messages) {
          messages[messageId] = { ...messages[messageId], isUnread: false };
        }
      }
    },
    setAssistantMessageScore: (
      state,
      action: PayloadAction<AssistantMessageScoreDto>
    ) => {
      const dto = action.payload;
      state.assistantMessageScores[dto.message_assistant_id] = dto.score;
    },
    setAssistantMessageScores: (
      state,
      action: PayloadAction<AssistantMessageScoreDto[]>
    ) => {
      state.assistantMessageScores = {};
      for (const dto of action.payload) {
        state.assistantMessageScores[dto.message_assistant_id] = dto.score;
      }
    },
  },
});

export const {
  refreshWebsocket,
  setConnected,
  setChats,
  setChat,
  delChat,
  setInputWithClient,
  setInputWithAssistant,
  appendLetsTalkInputWithClient,
  appendLetsTalkInputWithAssistant,
  setLetsTalkInputWithClient,
  setLetsTalkInputWithAssistant,
  clearMessagesWithClient,
  clearMessagesWithAssistant,
  clearMessagesWithLetsTalkClient,
  clearMessagesWithLetsTalkAssistant,
  pushClientMessage,
  pushAssistantMessage,
  pushUserToClientMessage,
  pushUserToAssistantMessage,
  pushUserToWebsocketMessage,
  pushLetsTalkClientMessage,
  pushLetsTalkAssistantMessage,
  pushLetsTalkUserToClientMessage,
  pushLetsTalkUserToAssistantMessage,
  copyToClientInput,
  copyToAssistantInput,
  readAllMessagesWithClient,
  setAssistantMessageScore,
  setAssistantMessageScores,
} = chatSlice.actions;

export const selectConnected = (state: RootState) => state.chat.isConnected;

export const selectWebsocket = (state: RootState) => state.chat.webSocket;

export const selectChats = (state: RootState) => state.chat.chats;

export const selectCurrentChat = (state: RootState) =>
  state.client.currentClientId
    ? state.chat.chats[state.client.currentClientId]
    : undefined;

export const selectCurrentChatInput = (state: RootState) =>
  state.client.currentClientId
    ? state.chat.chatInputs[state.client.currentClientId]
    : undefined;

export const selectMessagesWithAssistant = createSelector(
  selectCurrentChat,
  (chat) => {
    const map = chat?.messagesWithAssistant || {};
    const messages: ChatMessageWithAssistant[] = [];
    for (const messageId in map) {
      messages.push(map[messageId]);
    }
    messages.sort(messageCreatedAtComparator);
    return messages;
  }
);

export const selectMessagesWithClient = createSelector(
  selectCurrentChat,
  (chat) => {
    const map = chat?.messagesWithClient || {};
    const messages: ChatMessageWithClient[] = [];
    for (const messageId in map) {
      messages.push(map[messageId]);
    }
    messages.sort(messageCreatedAtComparator);
    return messages;
  }
);

export const selectLetsTalkInputWithClient = (state: RootState) =>
  state.chat.letsTalkInput.inputWithClient;

export const selectLetsTalkInputWithAssistant = (state: RootState) =>
  state.chat.letsTalkInput.inputWithAssistant;

const selectLetsTalkMessagesMapWithClient = (state: RootState) =>
  state.chat.letsTalkChat.messagesWithClient;
const selectLetsTalkMessagesMapWithAssistant = (state: RootState) =>
  state.chat.letsTalkChat.messagesWithAssistant;

export const selectLetsTalkMessagesWithClient = createSelector(
  selectLetsTalkMessagesMapWithClient,
  (map) => {
    const messages: ChatMessageWithClient[] = [];
    for (const messageId in map) {
      messages.push(map[messageId]);
    }
    messages.sort(messageCreatedAtComparator);
    return messages;
  }
);

export const selectLetsTalkMessagesWithAssistant = createSelector(
  selectLetsTalkMessagesMapWithAssistant,
  (map) => {
    const messages: ChatMessageWithAssistant[] = [];
    for (const messageId in map) {
      messages.push(map[messageId]);
    }
    messages.sort(messageCreatedAtComparator);
    return messages;
  }
);

export const selectAssistantMessageScore =
  (messageId: string) => (state: RootState) =>
    state.chat.assistantMessageScores[messageId];

export const combineMessagesWithClient = (
  messages?: ChatMessageWithClient[]
) => {
  const combinedMessages: ChatMessageWithClient[] = [];
  if (!messages) {
    return combinedMessages;
  }
  for (const message of messages) {
    const n = combinedMessages.length;
    if (n === 0 || message.role === 'user') {
      combinedMessages.push(message);
      continue;
    }
    if (message.role !== combinedMessages[n - 1].role) {
      combinedMessages.push(message);
      continue;
    }
    const { content, ...others } = combinedMessages[n - 1];
    combinedMessages[n - 1] = {
      content: `${content.trimEnd()} ${BREAK_TOKEN} ${message.content.trimStart()}`,
      ...others,
    };
  }
  return combinedMessages;
};

// adapted from https://archive.is/lZAM4#selection-633.23-633.47
export const exportMessagesPlainText = (
  messages: ChatMessage[],
  options?: {
    messagesLabel?: string;
    assistantLabel?: string;
    userLabel?: string;
  }
) => {
  const assistantLabel = options?.assistantLabel || 'Assistant';
  const userLabel = options?.userLabel || 'User';
  const parts = messages.map(
    (m) =>
      `<<${m.role === 'assistant' ? assistantLabel : userLabel} | ${
        m.createdAt
      }>>\n${m.content}\n\n`
  );
  const file = new Blob(parts, { type: 'text/plain;charset=utf-8' });
  const el = document.createElement('a');
  el.href = URL.createObjectURL(file);
  el.download = `${options?.messagesLabel || 'messages'}.txt`;
  document.body.appendChild(el);
  el.click();
};

export default chatSlice.reducer;
