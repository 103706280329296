import { Image } from '@nextui-org/react';

const MOHT_LOGO_SRC = process.env.PUBLIC_URL + '/logo_moht_row.png';

export default function Footer() {
  return (
    <div className="flex flex-row justify-end w-full">
      <Image src={MOHT_LOGO_SRC} className="h-12 mx-2" />
    </div>
  );
}
