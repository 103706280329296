import { ThunkAction, UnknownAction, configureStore } from '@reduxjs/toolkit';
import assistantReducer from './assistant/assistantSlice';
import authReducer from './auth/authSlice';
import chatReducer from './chat/chatSlice';
import clientReducer from './client/clientSlice';
import settingsReducer from './settings/settingsSlice';

const store = configureStore({
  reducer: {
    assistant: assistantReducer,
    auth: authReducer,
    chat: chatReducer,
    client: clientReducer,
    settings: settingsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // ignored since WebSocket is not serializable
      // see https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
      serializableCheck: { ignoredPaths: ['chat.webSocket'] },
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  UnknownAction
>;

export default store;
