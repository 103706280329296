import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from '../../types';
import { RootState } from '../store';
import { saveJwt } from '../../util';

interface AuthState {
  user?: User;
}

const initialState: AuthState = {};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuth: (state) => {
      delete state.user;
      saveJwt();
    },
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload;
    },
  },
});

export const { clearAuth, setUser } = authSlice.actions;

export const selectLoggedIn = (state: RootState) => !!state.auth.user;

export const selectDisplayName = (state: RootState) =>
  state.auth.user?.name || state.auth.user?.username;

export default authSlice.reducer;
