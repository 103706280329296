import ClientView from '../components/client/ClientView';
import AssistantView from '../components/assistant/AssistantView';
import NavBar from '../components/base/NavBar';
import ChatList from '../components/chat/ChatList';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectLoggedIn } from '../store/auth/authSlice';
import { useEffect, useState } from 'react';
import {
  getAssistantMessageScoresThunk,
  listChats,
} from '../store/chat/chatThunks';
import { refreshWebsocket } from '../store/chat/chatSlice';
import { selectAppMode } from '../store/settings/settingsSlice';
import LetsTalkView from '../components/assistant/LetsTalkView';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { MOTION_KEYS } from '../util';
import Footer from '../components/base/Footer';
import { Button, Divider } from '@nextui-org/react';
import { FaChevronRight } from 'react-icons/fa6';

export default function ChatPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectLoggedIn);
  const appMode = useAppSelector(selectAppMode);
  const [chatListShown, setChatListShown] = useState(true);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [navigate, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(listChats());
      dispatch(refreshWebsocket());
      dispatch(getAssistantMessageScoresThunk());
    }
  }, [dispatch, isLoggedIn]);

  const toggleChatList = () => setChatListShown((isShown) => !isShown);

  return (
    <div className="flex flex-col items-center w-screen h-screen">
      <NavBar />
      <Divider orientation="horizontal" />
      {/* height deducted for navbar and footer */}
      <div className="relative w-full h-[calc(100%-4rem-4rem)]">
        <AnimatePresence mode="wait">
          {appMode === 'dialogue' && (
            <motion.div
              key="dialogue-group"
              variants={{
                [MOTION_KEYS.INITIAL]: { opacity: 0 },
                [MOTION_KEYS.ANIMATE]: {
                  opacity: 1,
                  transition: { staggerChildren: 0.1 },
                },
                [MOTION_KEYS.EXIT]: {
                  opacity: 0,
                  transition: { staggerChildren: 0.1 },
                },
              }}
              initial={MOTION_KEYS.INITIAL}
              animate={MOTION_KEYS.ANIMATE}
              exit={MOTION_KEYS.EXIT}
              className="relative flex flex-row w-full h-full"
            >
              <LayoutGroup>
                <AnimatePresence>
                  {chatListShown && (
                    <motion.div
                      variants={{
                        [MOTION_KEYS.INITIAL]: { x: '-100%' },
                        [MOTION_KEYS.ANIMATE]: { x: '0%' },
                      }}
                      exit={{ x: '-100%' }}
                      className="relative flex flex-shrink-0 flex-row w-72 z-10"
                    >
                      <ChatList />
                      <Divider orientation="vertical" />
                    </motion.div>
                  )}
                </AnimatePresence>
                <motion.div
                  layout="preserve-aspect"
                  className="relative grow grid grid-rows-1 grid-cols-2 gap-4 m-4"
                >
                  <div className="col-span-1 border-1">
                    <ClientView />
                  </div>
                  <div className="col-span-1 border-1">
                    <AssistantView />
                  </div>
                </motion.div>
              </LayoutGroup>
            </motion.div>
          )}
          {appMode === 'letstalk' && (
            <motion.div
              key="letstalk-group"
              variants={{
                [MOTION_KEYS.INITIAL]: { opacity: 0 },
                [MOTION_KEYS.ANIMATE]: { opacity: 1 },
                [MOTION_KEYS.EXIT]: { opacity: 0 },
              }}
              initial={MOTION_KEYS.INITIAL}
              animate={MOTION_KEYS.ANIMATE}
              exit={MOTION_KEYS.EXIT}
              className={
                'absolute left-0 right-0 ml-auto mr-auto ' +
                'flex w-full h-full max-w-screen-lg'
              }
            >
              <LetsTalkView />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <Divider orientation="horizontal" />
      <div className="flex flex-1 flex-row items-center w-full">
        <motion.div
          animate={{ rotateY: chatListShown ? 180 : 0 }}
          className="mx-2"
        >
          <Button
            isIconOnly
            radius="full"
            variant="light"
            onPress={toggleChatList}
          >
            <FaChevronRight />
          </Button>
        </motion.div>
        <Footer />
      </div>
    </div>
  );
}
