import { toast } from 'react-toastify';
import { ApiError, PromptType, SettingsFeature } from './types';

const JWT_KEY = 'jwt';
const UNKNOWN_ERR_MSG = 'An unknown error has occurred!';

export const APP = {
  TITLE: {
    DEFAULT: 'Dialogue AI',
    SHORT: 'Dialogue',
    FULL: 'Dual Dialogue AI Demonstrator',
  },
};

/**
 * Keys for `motion` properties.
 */
export const MOTION_KEYS = {
  INITIAL: 'initial',
  ANIMATE: 'visible',
  EXIT: 'exiting',
};

const CLIENT_LABEL = 'affected person';
export const DEFAULT_FEATURE_SETTINGS: SettingsFeature[] = [
  {
    key: 'generate_response',
    label: 'Propose short response',
    description: `Generate a brief response based on the conversation with the ${CLIENT_LABEL}`,
    isEnabled: true,
    isAutoRun: false,
    isAutoRunnable: true,
  },
  {
    key: 'generate_forum_response',
    label: 'Propose long response',
    description: `Generate a comprehensive response based on the conversation with the ${CLIENT_LABEL}`,
    isEnabled: true,
    isAutoRun: false,
    isAutoRunnable: false,
  },
  {
    key: 'recommend_resource',
    label: 'Recommend resources',
    description: `Suggest useful resources based on the conversation with the ${CLIENT_LABEL}`,
    isEnabled: true,
    isAutoRun: false,
    isAutoRunnable: true,
  },
  {
    key: 'generate_feedback',
    label: 'Analyse conversation',
    description: `Provide feedback based on the conversation with the ${CLIENT_LABEL}`,
    isEnabled: false,
    isAutoRun: false,
    isAutoRunnable: false,
  },
  {
    key: 'summarize',
    label: 'Summarise conversation',
    description: `Summarise the conversation with the ${CLIENT_LABEL}`,
    isEnabled: false,
    isAutoRun: false,
    isAutoRunnable: false,
  },
  {
    key: 'empathetic_rewrite',
    label: 'Rewrite empathetically',
    description: 'Rewrite the draft message in an empathetic manner',
    isEnabled: false,
    isAutoRun: false,
    isAutoRunnable: false,
  },
];

/**
 * Checks a `fetch` response for errors.
 *
 * @param fetchPromise Response promise from `fetch`.
 * @param options Optional toast notifications on error.
 * @returns The passed-through `Response` if successful.
 */
export async function checkResponse(
  fetchPromise: Promise<Response>,
  options?: {
    toastOnApiError?: boolean;
    toastOnFetchError?: boolean;
    toastOnFetchErrorMessage?: string;
  }
) {
  try {
    const res = await fetchPromise;
    if (res.ok) {
      return res;
    }
    if (options?.toastOnApiError) {
      const error: ApiError = await res.json();
      toast.error(error.detail || UNKNOWN_ERR_MSG);
    }
  } catch (e) {
    console.error(e);
    if (options?.toastOnFetchError) {
      toast.error(options.toastOnFetchErrorMessage || UNKNOWN_ERR_MSG);
    }
  }
}

/**
 * `fetch` wrapper with authorization header.
 */
export async function fetchWithAuth(
  input: RequestInfo | URL,
  init?: RequestInit
) {
  const jwt = loadJwt();
  const { ...options } = init || {};
  if (jwt) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${jwt}`,
    };
  } else {
    options.credentials = 'include';
  }
  return fetch(input, options);
}

/**
 * Loads the JSON web token from local storage.
 *
 * @returns The loaded JWT, if any.
 */
export function loadJwt() {
  return localStorage.getItem(JWT_KEY);
}

/**
 * Saves the given JSON web token to local storage.
 * If `jwt` is falsy, it is removed from local storage.
 *
 * @param jwt JSON web token to save.
 */
export function saveJwt(jwt?: string) {
  if (jwt) {
    localStorage.setItem(JWT_KEY, jwt);
  } else {
    localStorage.removeItem(JWT_KEY);
  }
}

/**
 * @param iso ISO datetime string.
 * @returns Friendly datetime string.
 */
export function formatIsoToLocaleDateTime(iso: string) {
  return Date.parse(iso)
    ? new Date(iso).toLocaleString('en-GB', {
        weekday: 'short',
        year: '2-digit',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    : undefined;
}

/**
 * Quotes a message in markdown based on the given prompt type.
 * @param message Message to be quoted.
 * @param promptType Prompt type to specify additional message.
 * @returns Quoted message.
 */
export function quoteMessage(message: string, promptType?: PromptType) {
  const quoted = `> ${message.trim().split(/\n+/).join('  \n')}`;
  switch (promptType) {
    case 'empathetic_rewrite':
      return `***Rewrite in an empathetic manner***\n\n${quoted}`;
    case 'generate_feedback':
      return `***Analyse conversation***\n\n${quoted}`;
    case 'generate_response':
      return `***Propose message** after*\n\n${quoted}`;
    case 'recommend_resource':
      return `***Recommend resources** for*\n\n${quoted}`;
    case 'summarize':
      return `***Summarise conversation***\n\n${quoted}`;
    case 'direct':
    default:
      return message;
  }
}
