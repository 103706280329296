import { Card, CardBody, Chip } from '@nextui-org/react';
import { useMemo } from 'react';

export interface ChatViewProps {
  chatId?: string;
  chatBox?: JSX.Element;
}

export default function ChatView({ chatId, chatBox }: ChatViewProps) {
  const wrappedChatBox = useMemo(
    () =>
      chatId &&
      chatBox && (
        <CardBody key="chat-box" className="p-0">
          {chatBox}
        </CardBody>
      ),
    [chatId, chatBox]
  );

  return (
    <Card radius="none" shadow="none" className="relative w-full h-full">
      {!chatId && (
        <Chip variant="flat" className="m-auto">
          Select a chat
        </Chip>
      )}
      {wrappedChatBox}
    </Card>
  );
}
