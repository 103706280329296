import { Button, ButtonProps, Chip, Tooltip } from '@nextui-org/react';
import { SettingsFeature } from '../../types';

export interface FeatureItemProps {
  feature: SettingsFeature;
  onPress?: () => void;
  buttonProps?: ButtonProps;
}

export default function FeatureItem({
  feature,
  onPress,
  buttonProps,
}: FeatureItemProps) {
  const autorunIndicator = feature.isAutoRun ? (
    // "A" for auto-run
    <Chip size="sm" color="success" radius="sm" variant="flat">
      A
    </Chip>
  ) : undefined;

  return (
    <Tooltip key={feature.key} content={feature.description}>
      <Button
        onPress={onPress}
        endContent={autorunIndicator}
        {...buttonProps}
        variant="flat"
      >
        {feature.label}
      </Button>
    </Tooltip>
  );
}
