import {
  Button,
  ButtonProps,
  Textarea,
  TextAreaProps,
} from '@nextui-org/react';
import { useAnimate } from 'framer-motion';
import { ChangeEvent, FormEvent, useRef } from 'react';
import { FaArrowUp } from 'react-icons/fa6';
import { motion } from 'framer-motion';

export interface ChatInputBaseProps {
  /**
   * Key used by `motion` components to animate message submission.
   */
  textKey?: string;
  textValue?: string;
  onTextValueChange?: (value: string) => void;
  onTextValueSubmit?: (value: string) => void;
  submitButtonProps?: ButtonProps;
  textAreaProps?: TextAreaProps;
}

export default function ChatInputBase({
  textKey,
  textValue,
  onTextValueChange,
  onTextValueSubmit,
  submitButtonProps,
  textAreaProps,
}: ChatInputBaseProps) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [scopeArrowUp, animateArrowUp] = useAnimate<HTMLSpanElement>();
  const [scopeForm, animateForm] = useAnimate<HTMLFormElement>();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const value = textValue?.trim();
    if (value?.length && onTextValueSubmit) {
      onTextValueSubmit(value);
      handleInputClear();
      animateArrowUp(scopeArrowUp.current, {
        y: [0, '20%', '-40%', '10%', 0],
      });
      animateForm(scopeForm.current, {
        y: [0, -8, 2, 0],
      });
    }
    inputRef.current?.focus();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onTextValueChange) {
      onTextValueChange(e.target.value);
    }
  };

  const handleInputClear = () => {
    if (onTextValueChange) {
      onTextValueChange('');
    }
  };

  const handleInputEnter = (
    e: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      scopeForm.current.requestSubmit();
    }
  };

  /**
   * Filler to animate message submission animations from.
   */
  const textAreaFiller = textKey ? (
    <motion.div
      key={textKey}
      layoutId={textKey}
      className="absolute w-full h-full"
    />
  ) : undefined;

  const textArea = (
    <Textarea
      minRows={1}
      placeholder="Write something..."
      {...textAreaProps}
      ref={inputRef}
      id="input"
      name="input"
      type="text"
      autoComplete="off"
      value={textValue}
      onClear={handleInputClear}
      onChange={handleInputChange}
      onKeyDown={handleInputEnter}
    />
  );

  return (
    <form
      ref={scopeForm}
      onSubmit={handleSubmit}
      className="relative flex flex-row w-full gap-2 border-1 p-2 rounded-2xl backdrop-blur-md transition-shadow shadow-none focus-within:shadow-small"
    >
      <div className="relative flex w-full">
        {textAreaFiller}
        {textArea}
      </div>
      <Button
        isIconOnly
        isDisabled={textValue?.trim().length === 0}
        color="primary"
        {...submitButtonProps}
        type="submit"
      >
        {submitButtonProps?.children || (
          <span ref={scopeArrowUp}>
            <FaArrowUp className="text-white" />
          </span>
        )}
      </Button>
    </form>
  );
}
