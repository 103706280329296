import { PropsWithChildren } from 'react';

export interface SettingsRowProps extends PropsWithChildren {
  header: string;
  description?: string;
}

export default function SettingsRow({
  header,
  description,
  children,
}: SettingsRowProps) {
  return (
    <div className="flex flex-row justify-between items-center gap-2">
      <div className="flex flex-col justify-center">
        <p className="text-base font-semibold">{header}</p>
        {description && <p className="text-sm">{description}</p>}
      </div>
      {children}
    </div>
  );
}
