import ChatBubbleSingle, { ChatBubbleSingleProps } from './ChatBubbleSingle';

const BREAK_REGEX = /\s*<break>\s*/;

export default function ChatBubbleMultiple({
  message,
  ...others
}: ChatBubbleSingleProps) {
  return (
    <div
      className={`relative flex flex-col gap-2 ${
        others.role === 'assistant' ? 'items-start' : 'items-end'
      }`}
    >
      {message.split(BREAK_REGEX).map((part, i) => (
        <ChatBubbleSingle {...others} key={i} message={part} />
      ))}
    </div>
  );
}
