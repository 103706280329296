import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PayloadRisk, RiskType } from '../../types';
import { RootState } from '../store';

interface AssistantState {
  riskOfChats: { [chatId: string]: RiskType };
  // TODO: replace `content` with message UUID
  riskOfMessages: { [content: string]: RiskType };
}

const initialState: AssistantState = {
  riskOfChats: {},
  riskOfMessages: {},
};

export const assistantSlice = createSlice({
  name: 'assistant',
  initialState,
  reducers: {
    addRiskOfChat: (state, action: PayloadAction<PayloadRisk>) => {
      const { key, risk } = action.payload;
      state.riskOfChats[key] = risk;
    },
    addRiskOfMessage: (state, action: PayloadAction<PayloadRisk>) => {
      const { key, risk } = action.payload;
      state.riskOfMessages[key] = risk;
    },
  },
});

export const { addRiskOfChat, addRiskOfMessage } = assistantSlice.actions;

export const selectRiskOfChats = (state: RootState) =>
  state.assistant.riskOfChats;

export const selectRiskOfMessages = (state: RootState) =>
  state.assistant.riskOfMessages;

export const riskToLabel = (
  risk: RiskType
): { content: string; color: 'default' | 'success' | 'warning' | 'danger' } => {
  switch (risk) {
    case 'low':
      return { content: 'Low risk', color: 'success' };
    case 'medium':
      return { content: 'Medium risk', color: 'warning' };
    case 'high':
      return { content: 'High risk', color: 'danger' };
    case 'neutral':
      return { content: 'Neutral risk', color: 'default' };
    default:
      return { content: 'Unknown risk', color: 'default' };
  }
};

export default assistantSlice.reducer;
