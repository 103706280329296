import {
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Selection,
  Spacer,
  Switch,
} from '@nextui-org/react';
import SettingsToggle from './SettingsToggle';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  selectActorConfig,
  selectAssistantConfig,
  selectSettingsDeveloper,
  selectSettingsFeatures,
  setActorModel,
  setAssistantModel,
  setSettingsDeveloper,
} from '../../store/settings/settingsSlice';
import { ModelTag } from '../../types';
import { PropsWithChildren, useMemo } from 'react';
import { FaAngleDown } from 'react-icons/fa6';
import SettingsRow from './SettingsRow';
import { APP } from '../../util';
import PromptButton from '../prompt/PromptButton';

const MODELS: { tag: ModelTag; label: string }[] = [
  { tag: 'gpt-4o', label: 'GPT-4o' },
  { tag: 'gpt-4o-mini', label: 'GPT-4o mini' },
  { tag: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo' },
  { tag: 'llama3-8b-instruct', label: 'Llama3 8b Instruct' },
  { tag: 'llama3-70b-instruct', label: 'Llama3 70b Instruct' },
  { tag: 'mistral-7b-instruct', label: 'Mistral 7b Instruct' },
  { tag: 'mistral-large', label: 'Mistral Large' },
  { tag: 'mixtral-8x7b-instruct', label: 'Mixtral 8x7b Instruct' },
  { tag: 'claude-3-haiku', label: 'Claude 3 Haiku' },
  { tag: 'claude-3-sonnet', label: 'Claude 3 Sonnet' },
  { tag: 'claude-3.5-sonnet', label: 'Claude 3.5 Sonnet' },
];

export interface SettingsModalProps {
  isOpen: boolean;
  onOpenChange: () => void;
}

export default function SettingsModal({
  isOpen,
  onOpenChange,
}: SettingsModalProps) {
  const dispatch = useAppDispatch();
  const features = useAppSelector(selectSettingsFeatures);
  const actorConfig = useAppSelector(selectActorConfig);
  const assistantConfig = useAppSelector(selectAssistantConfig);
  const settingsDev = useAppSelector(selectSettingsDeveloper);

  const selectedActorModel = useMemo(
    () => new Set([actorConfig.modelTag]),
    [actorConfig]
  );
  const selectedAssistantModel = useMemo(
    () => new Set([assistantConfig.modelTag]),
    [assistantConfig]
  );

  const selectedActorModelLabel = useMemo(() => {
    for (const model of MODELS) {
      if (model.tag === actorConfig.modelTag) {
        return model.label;
      }
    }
    return 'Unknown';
  }, [actorConfig]);

  const selectedAssistantModelLabel = useMemo(() => {
    for (const model of MODELS) {
      if (model.tag === assistantConfig.modelTag) {
        return model.label;
      }
    }
    return 'Unknown';
  }, [assistantConfig]);

  const handleSelectedAssistantModelChange = (keys: Selection) => {
    if (keys === 'all') {
      return;
    }
    // keys only contains 1 ModelTag
    for (const key of keys) {
      // @ts-ignore
      dispatch(setAssistantModel(key));
    }
  };

  const handleSelectedActorModelChange = (keys: Selection) => {
    if (keys === 'all') {
      return;
    }
    // keys only contains 1 ModelTag
    for (const key of keys) {
      // @ts-ignore
      dispatch(setActorModel(key));
    }
  };

  const handleToggleMessageModelTag = (isShown: boolean) => {
    dispatch(
      setSettingsDeveloper({ ...settingsDev, showMessageModelTag: isShown })
    );
  };

  const handleToggleSelfPlayOptions = (isShown: boolean) => {
    dispatch(
      setSettingsDeveloper({ ...settingsDev, showSelfPlayOptions: isShown })
    );
  };

  const handleToggleAutoRiskEvaluation = (isAuto: boolean) => {
    dispatch(
      setSettingsDeveloper({ ...settingsDev, autoEvaluateMessageRisk: isAuto })
    );
  };

  const handleResetEverything = () => {
    localStorage.clear();
    window.location.reload();
  };

  const modelDropdowns = (
    <>
      <SettingsRow header="Assistant model">
        <Dropdown>
          <DropdownTrigger>
            <Button variant="flat" endContent={<FaAngleDown />}>
              {selectedAssistantModelLabel}
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Assistant model dropdown"
            variant="flat"
            selectionMode="single"
            selectedKeys={selectedAssistantModel}
            onSelectionChange={handleSelectedAssistantModelChange}
          >
            {MODELS.map((m) => (
              <DropdownItem key={m.tag}>{m.label}</DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </SettingsRow>
      <SettingsRow header="Actor model">
        <Dropdown>
          <DropdownTrigger>
            <Button variant="flat" endContent={<FaAngleDown />}>
              {selectedActorModelLabel}
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Actor model dropdown"
            variant="flat"
            selectionMode="single"
            selectedKeys={selectedActorModel}
            onSelectionChange={handleSelectedActorModelChange}
          >
            {MODELS.map((m) => (
              <DropdownItem key={m.tag}>{m.label}</DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </SettingsRow>
    </>
  );

  const devOptions = (
    <>
      <SettingsRow
        header="Message model tag"
        description="Whether to show model tag for assistant messages"
      >
        <Switch
          aria-label="is shown"
          isSelected={settingsDev.showMessageModelTag}
          onValueChange={handleToggleMessageModelTag}
        />
      </SettingsRow>
      <SettingsRow
        header="Self-play options"
        description="Whether to show self-play options in chats with actors"
      >
        <Switch
          aria-label="is shown"
          isSelected={settingsDev.showSelfPlayOptions}
          onValueChange={handleToggleSelfPlayOptions}
        />
      </SettingsRow>
      <SettingsRow
        header="Auto suicidal risk evaluation of incoming messages"
        description={
          'Whether to automatically evaluate ' +
          'the suicidal risk of messages from affected persons'
        }
      >
        <Switch
          aria-label="is auto"
          isSelected={settingsDev.autoEvaluateMessageRisk}
          onValueChange={handleToggleAutoRiskEvaluation}
        />
      </SettingsRow>
      <SettingsRow
        header="Reset everything"
        description={
          'Deletes ALL settings, and other local data ' +
          '(usually only for debugging purposes)'
        }
      >
        <Button color="danger" variant="flat" onPress={handleResetEverything}>
          Reset
        </Button>
      </SettingsRow>
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size="2xl"
      backdrop="blur"
      scrollBehavior="inside"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader>Settings</ModalHeader>
            <ModalBody className="border-y-1">
              <SettingsSection header={`${APP.TITLE.DEFAULT} feature toggles`}>
                <p className="text-xs italic">
                  Features with an "A" toggle can be automatically run on
                  message received from an affected person.
                </p>
                {features.map((feat) => (
                  <SettingsToggle key={feat.key} feature={feat} />
                ))}
                <Divider />
                <PromptButton />
              </SettingsSection>
              <SettingsSection header="Model selection">
                {modelDropdowns}
              </SettingsSection>
              <SettingsSection header="Developer options">
                {devOptions}
              </SettingsSection>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onPress={onClose}>
                Done
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}

function SettingsSection({
  header,
  children,
}: { header: string } & PropsWithChildren) {
  return (
    <>
      <p className="text-sm font-semibold">{header}</p>
      <Divider />
      {children}
      <Spacer y={4} />
    </>
  );
}
