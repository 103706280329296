import { v4 as uuidv4 } from 'uuid';
import ChatBox from '../chat/ChatBox';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  combineMessagesWithClient,
  copyToAssistantInput,
  exportMessagesPlainText,
  pushUserToAssistantMessage,
  readAllMessagesWithClient,
  selectMessagesWithClient,
} from '../../store/chat/chatSlice';
import ChatView from '../chat/ChatView';
import {
  selectCurrentActor,
  selectCurrentClient,
} from '../../store/client/clientSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  Input,
  Tooltip,
} from '@nextui-org/react';
import {
  archiveChat,
  deleteChatWithClient,
  directChat,
  queryActor,
} from '../../store/chat/chatThunks';
import { evaluateRiskOfChat } from '../../store/assistant/assistantThunks';
import {
  FaBoxArchive,
  FaFileArrowDown,
  FaTrafficLight,
  FaTrash,
} from 'react-icons/fa6';
import ButtonWithConfirmModal from '../base/ButtonWithConfirmModal';
import { QueryActorReqDto } from '../../store/chat/chatDto';
import { selectSettingsDeveloper } from '../../store/settings/settingsSlice';
import ChatInputClient from '../chat/input/ChatInputClient';
import { AnimatePresence } from 'framer-motion';

const MIN_RALLY_COUNT = 0;

export default function ClientView() {
  const dispatch = useAppDispatch();
  const currentClient = useAppSelector(selectCurrentClient);
  const currentActor = useAppSelector(selectCurrentActor);
  const messagesWithClient = useAppSelector(selectMessagesWithClient);
  const settingsDev = useAppSelector(selectSettingsDeveloper);
  const [isTypingSimulated, setIsTypingSimulated] = useState(true);
  const [isAutoReply, setIsAutoReply] = useState(true);
  const [rallyCount, setRallyCount] = useState(MIN_RALLY_COUNT);
  const [isSelfPlay, setIsSelfPlay] = useState(false);
  const [hasUserReply, setHasUserReply] = useState(false);

  const clientName = useMemo(
    () =>
      currentClient?.friendlyName ||
      currentClient?.username ||
      currentClient?.userId,
    [currentClient]
  );

  const queryActorDto: QueryActorReqDto = useMemo(() => {
    const n = messagesWithClient.length;
    const actor = currentActor?.userId || '';
    const prompt = currentActor?.prompt;
    const isLastMessageUser =
      n > 0 && messagesWithClient[n - 1].role === 'user';
    const content = isLastMessageUser ? messagesWithClient[n - 1].content : '';
    const history = combineMessagesWithClient(
      isLastMessageUser
        ? messagesWithClient.slice(0, n - 1)
        : messagesWithClient
    );
    return { actor, prompt, content, history };
  }, [currentActor, messagesWithClient]);

  const handleActorSelfPlayStart = () => {
    if (rallyCount < 1) {
      return;
    }
    setIsSelfPlay(true);
    setHasUserReply(true);
  };

  const handleActorNormalStart = () => {
    triggerActor();
  };

  const triggerActor = useCallback(() => {
    setHasUserReply(false);
    dispatch(queryActor(queryActorDto, isTypingSimulated));
  }, [dispatch, queryActorDto, isTypingSimulated]);

  const triggerSelfPlay = useCallback(() => {
    setHasUserReply(false);
    setRallyCount((c) => c - 1);
    dispatch(queryActor(queryActorDto, isTypingSimulated, true));
  }, [dispatch, queryActorDto, isTypingSimulated]);

  useEffect(() => {
    // read all messages on chat opened
    if (!messagesWithClient || !currentClient) {
      return;
    }
    if (messagesWithClient.filter((m) => m.isUnread).length === 0) {
      return;
    }
    dispatch(readAllMessagesWithClient(currentClient.userId));
  }, [dispatch, messagesWithClient, currentClient]);

  useEffect(() => {
    const n = messagesWithClient.length;
    if (n === 0 || messagesWithClient[n - 1].role === 'user') {
      setHasUserReply(true);
    }
  }, [messagesWithClient]);

  useEffect(() => {
    // self-play functionality
    if (!isSelfPlay || !hasUserReply) {
      return;
    }
    if (rallyCount < 1) {
      setIsSelfPlay(false);
      return;
    }

    triggerSelfPlay();
  }, [isSelfPlay, rallyCount, hasUserReply, triggerSelfPlay]);

  useEffect(() => {
    const n = messagesWithClient.length;
    if (isSelfPlay || currentClient?.userType !== 'actor' || n === 0) {
      return;
    }
    if (isAutoReply && messagesWithClient[n - 1].role === 'user') {
      setHasUserReply(false);
      triggerActor();
    }
  }, [
    isSelfPlay,
    isAutoReply,
    hasUserReply,
    currentClient,
    messagesWithClient,
    triggerActor,
  ]);

  const handleTransfer = (message: string, sendImmediately = false) => {
    const userId = currentClient?.userId;
    if (!userId) {
      return;
    }
    const payload = message.trim();
    if (sendImmediately) {
      dispatch(
        pushUserToAssistantMessage({
          userId,
          payload,
          messageId: uuidv4(),
        })
      );
      dispatch(directChat(payload));
    } else {
      dispatch(copyToAssistantInput({ userId, payload }));
    }
  };

  const handleArchive = () => {
    if (currentClient?.userId) {
      dispatch(archiveChat(currentClient.userId));
    }
  };

  const handleClear = () => {
    if (currentClient?.userId) {
      dispatch(deleteChatWithClient(currentClient.userId));
    }
  };

  const handleExportPlainText = () => {
    if (currentClient && messagesWithClient) {
      exportMessagesPlainText(messagesWithClient, {
        messagesLabel: `${clientName} [${currentClient.userId}]`,
        assistantLabel: clientName,
        userLabel: 'Volunteer',
      });
    }
  };

  const handleEvaluateChatRisk = () => {
    if (messagesWithClient && currentClient) {
      dispatch(evaluateRiskOfChat(currentClient.userId, messagesWithClient));
    }
  };

  const handleRallyCountChange = (value: string) => {
    setRallyCount(parseInt(value) || MIN_RALLY_COUNT);
  };

  const buttonTrafficLight = (
    <Tooltip content="Evaluate suicidal risk" placement="bottom">
      <Button onPress={handleEvaluateChatRisk}>
        <FaTrafficLight />
      </Button>
    </Tooltip>
  );

  const buttonExport = (
    <Tooltip content="Export as plain text" placement="bottom">
      <Button onPress={handleExportPlainText}>
        <FaFileArrowDown />
      </Button>
    </Tooltip>
  );

  const buttonArchive = (
    <ButtonWithConfirmModal
      onPress={handleArchive}
      buttonContent={<FaBoxArchive />}
      buttonProps={{ color: 'danger' }}
      tooltipProps={{
        content: 'Archive conversation',
        placement: 'bottom',
        color: 'danger',
      }}
      confirmLabel="Archive"
      modalHeader="Archive conversation?"
    />
  );

  const buttonClear = (
    <ButtonWithConfirmModal
      onPress={handleClear}
      buttonContent={<FaTrash />}
      buttonProps={{ color: 'danger', isDisabled: !messagesWithClient?.length }}
      tooltipProps={{
        content: 'Clear conversation',
        placement: 'bottom',
        color: 'danger',
      }}
      confirmLabel="Clear"
      modalHeader="Clear conversation?"
    />
  );

  const actorControls =
    currentClient?.userType === 'actor' ? (
      <Card shadow="none" className="border-1">
        <CardBody className="flex flex-col gap-2">
          {settingsDev.showSelfPlayOptions && (
            <Input
              size="sm"
              type="number"
              label="Number of back-and-forths"
              placeholder="e.g. 10"
              value={rallyCount.toString()}
              onValueChange={handleRallyCountChange}
              min={MIN_RALLY_COUNT}
            />
          )}
          <div className="flex flex-row gap-4">
            <Tooltip
              offset={20}
              placement="top-start"
              content="Adds a delay between messages to simulate typing"
            >
              <Checkbox
                size="sm"
                isSelected={isTypingSimulated}
                onValueChange={setIsTypingSimulated}
              >
                Simulated typing
              </Checkbox>
            </Tooltip>
            <Tooltip
              offset={20}
              placement="top-start"
              content="Actor replies automatically when a message is sent"
            >
              <Checkbox
                size="sm"
                isSelected={isAutoReply}
                onValueChange={setIsAutoReply}
              >
                Automatic replies
              </Checkbox>
            </Tooltip>
          </div>
        </CardBody>
        <CardFooter>
          <ButtonGroup variant="flat">
            <Button color="primary" onPress={handleActorNormalStart}>
              {messagesWithClient.length > 0 ? 'Continue' : 'Start'}
            </Button>
            {settingsDev.showSelfPlayOptions && (
              <Button
                color="success"
                onPress={handleActorSelfPlayStart}
                isDisabled={rallyCount < 1}
                isLoading={isSelfPlay}
              >
                Self-play
              </Button>
            )}
          </ButtonGroup>
        </CardFooter>
      </Card>
    ) : undefined;

  const chatBox = (
    <AnimatePresence mode="wait">
      {currentClient && (
        <ChatBox
          key={currentClient.userId}
          messages={messagesWithClient}
          onTransfer={handleTransfer}
          bottomIndicator={actorControls}
          headerTitle={clientName}
          headerElements={[
            <ButtonGroup key="chat-actions" isIconOnly variant="light">
              {buttonTrafficLight}
              {buttonExport}
              {buttonArchive}
              {buttonClear}
            </ButtonGroup>,
          ]}
          inputField={<ChatInputClient />}
        />
      )}
    </AnimatePresence>
  );

  return <ChatView chatId={currentClient?.userId} chatBox={chatBox} />;
}
