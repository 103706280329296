import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export interface MarkdownWrapperProps {
  children: string;
}

export default function MarkdownWrapper({ children }: MarkdownWrapperProps) {
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      className="prose"
      components={{
        // overwrite links to open in new tabs
        a: ({ children, target, ...others }) => (
          <a {...others} target="_blank">
            {children}
          </a>
        ),
      }}
    >
      {children}
    </Markdown>
  );
}
