import { FormEvent, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  ButtonGroup,
  Input,
  Listbox,
  ListboxItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Selection,
  Textarea,
  useDisclosure,
} from '@nextui-org/react';
import { FaAngleRight, FaPlus } from 'react-icons/fa6';
import ChatListItem from './ChatListItem';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  addActor,
  selectActors,
  selectCurrentClientId,
  setCurrentClient,
} from '../../store/client/clientSlice';
import {
  clearMessagesWithClient,
  selectChats,
} from '../../store/chat/chatSlice';
import MarkdownWrapper from '../base/MarkdownWrapper';

const HELP_TEXT = `You can use the following special tokens \
to inject preset instructions into your prompt:

- \`{background}\`: generic background to encourage \
backstory creation and emotional behaviour.
- \`{style_informal}\`: instructions to encourage imperfect English \
in a conversational style; also enables multiple-message functionality.

Example prompt:

\`\`\`text
You are role-playing a happy person...

{style_informal}

{background}

You are the happy person.
\`\`\`
`;

export default function ChatListActors() {
  const dispatch = useAppDispatch();
  const actors = useAppSelector(selectActors);
  const chats = useAppSelector(selectChats);
  const currentClientId = useAppSelector(selectCurrentClientId);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const actorsArray = useMemo(
    () => Array.from(Object.values(actors)),
    [actors]
  );

  const actorIds = useMemo(
    () => actorsArray.map((c) => c.userId),
    [actorsArray]
  );

  useEffect(() => {
    if (!currentClientId || !actorIds.includes(currentClientId)) {
      // current client is not an actor
      return;
    }
    if (currentClientId in chats) {
      // current client has a chat
      return;
    }

    dispatch(clearMessagesWithClient(currentClientId));
  }, [dispatch, actorIds, chats, currentClientId]);

  const handleSelectionChange = (keys: Selection) => {
    if (keys === 'all') {
      return;
    }
    for (const k of keys.values()) {
      const userId = k.toString();
      dispatch(setCurrentClient(userId));
    }
  };

  const handleCustomActorCreate =
    (onClose: () => void) => (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const form = new FormData(e.currentTarget);
      const username = form.get('username')?.toString();
      const prompt = form.get('prompt')?.toString();

      dispatch(
        addActor({
          userId: uuidv4(),
          userType: 'actor',
          username,
          prompt,
        })
      );
      onClose();
    };

  return (
    <div className="relative flex flex-col mb-2">
      <Listbox
        items={actorsArray}
        itemClasses={{ base: 'rounded-medium' }}
        variant="flat"
        selectionMode="single"
        selectedKeys={currentClientId ? [currentClientId] : []}
        onSelectionChange={handleSelectionChange}
        aria-label="Actors chat list"
      >
        {(c) => (
          <ListboxItem
            key={c.userId}
            textValue={c.userId}
            selectedIcon={({ isSelected }) =>
              isSelected && <FaAngleRight size={12} />
            }
          >
            <ChatListItem client={c} />
          </ListboxItem>
        )}
      </Listbox>
      <Button
        color="default"
        variant="flat"
        startContent={<FaPlus />}
        onPress={onOpen}
      >
        Custom actor
      </Button>
      <Modal size="xl" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <form onSubmit={handleCustomActorCreate(onClose)}>
              <ModalHeader>Create custom actor</ModalHeader>
              <ModalBody>
                <Input
                  autoFocus
                  isRequired
                  name="username"
                  placeholder="Friendly name"
                />
                <Textarea
                  isRequired
                  name="prompt"
                  placeholder="Custom prompt"
                />
                <MarkdownWrapper>{HELP_TEXT}</MarkdownWrapper>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup variant="solid">
                  <Button onPress={onClose}>Cancel</Button>
                  <Button type="submit" color="primary">
                    Create
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}
