import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  pushUserToAssistantMessage,
  selectCurrentChatInput,
  setInputWithAssistant,
} from '../../../store/chat/chatSlice';
import { selectCurrentClient } from '../../../store/client/clientSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { directChat } from '../../../store/chat/chatThunks';
import ChatInputBase from './ChatInputBase';
import ChatInputPlaceholder from './ChatInputPlaceholder';

export default function ChatInputAssistant() {
  const dispatch = useAppDispatch();
  const currentClient = useAppSelector(selectCurrentClient);
  const currentChatInput = useAppSelector(selectCurrentChatInput);
  const [textKey, setTextKey] = useState(uuidv4());

  const textValue = useMemo(
    () => currentChatInput?.inputWithAssistant || '',
    [currentChatInput]
  );

  const cycleTextKey = () => {
    const key = textKey;
    setTextKey(uuidv4());
    return key;
  };

  const handleInputChange = (payload: string) => {
    const userId = currentClient?.userId;
    if (userId) {
      dispatch(setInputWithAssistant({ userId, payload }));
    }
  };

  const handleInputSubmit = (value: string) => {
    const userId = currentClient?.userId;
    if (!userId) {
      return;
    }
    const payload = value.trim();
    dispatch(
      pushUserToAssistantMessage({ userId, payload, messageId: cycleTextKey() })
    );
    dispatch(directChat(payload));
  };

  return currentClient?.isArchived ? (
    <ChatInputPlaceholder text="Chat archived." />
  ) : (
    <ChatInputBase
      textKey={textKey}
      textValue={textValue}
      onTextValueChange={handleInputChange}
      onTextValueSubmit={handleInputSubmit}
      submitButtonProps={{
        className: 'bg-gradient-to-br from-orange-500 to-red-500',
      }}
      textAreaProps={{ placeholder: 'Message assistant' }}
    />
  );
}
