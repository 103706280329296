import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  SettingsFeature,
  SettingsAgentConfig,
  ModelTag,
  SettingsDeveloper,
  AppMode,
} from '../../types';
import { DEFAULT_FEATURE_SETTINGS } from '../../util';

const KEY_SETTINGS_DEV = 'settings_developer';

function loadSetting(key: string) {
  const value = localStorage.getItem(key);
  try {
    return JSON.parse(value || '{}');
  } catch {
    return {};
  }
}

function saveSetting(key: string, obj: object) {
  localStorage.setItem(key, JSON.stringify(obj));
}

function loadSettingsFeature(key: string) {
  return loadSetting(key);
}

function saveSettingsFeature(feature: SettingsFeature) {
  const { isAutoRun, isEnabled } = feature;
  saveSetting(feature.key, { isAutoRun, isEnabled });
}

function loadAgentConfig(key: string) {
  return loadSetting(key);
}

function saveAgentConfig(config: SettingsAgentConfig) {
  const { key, ...others } = config;
  saveSetting(key, others);
}

interface SettingsState {
  appMode: AppMode;
  dev: SettingsDeveloper;
  features: SettingsFeature[];
  actorConfig: SettingsAgentConfig;
  assistantConfig: SettingsAgentConfig;
}

const initialState: SettingsState = {
  appMode: 'dialogue',
  dev: {
    showMessageModelTag: false,
    showSelfPlayOptions: false,
    autoEvaluateMessageRisk: false,
    ...loadSetting(KEY_SETTINGS_DEV),
  },
  features: DEFAULT_FEATURE_SETTINGS.map((feat) => ({
    ...feat,
    ...loadSettingsFeature(feat.key),
  })),
  actorConfig: {
    key: 'actor',
    label: 'Actor',
    modelTag: 'gpt-4o',
    ...loadAgentConfig('actor'),
  },
  assistantConfig: {
    key: 'assistant',
    label: 'Assistant',
    modelTag: 'gpt-4o',
    ...loadAgentConfig('assistant'),
  },
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setAppMode: (state, action: PayloadAction<AppMode>) => {
      state.appMode = action.payload;
    },
    setSettingsDeveloper: (state, action: PayloadAction<SettingsDeveloper>) => {
      saveSetting(KEY_SETTINGS_DEV, action.payload);
      state.dev = action.payload;
    },
    setSettingsFeature: (state, action: PayloadAction<SettingsFeature>) => {
      saveSettingsFeature(action.payload);
      state.features = state.features.map((feat) =>
        feat.key === action.payload.key ? action.payload : feat
      );
    },
    setActorModel: (state, action: PayloadAction<ModelTag>) => {
      state.actorConfig.modelTag = action.payload;
      saveAgentConfig(state.actorConfig);
    },
    setAssistantModel: (state, action: PayloadAction<ModelTag>) => {
      state.assistantConfig.modelTag = action.payload;
      saveAgentConfig(state.assistantConfig);
    },
  },
});

export const {
  setAppMode,
  setSettingsDeveloper,
  setSettingsFeature,
  setActorModel,
  setAssistantModel,
} = settingsSlice.actions;

export const selectAppMode = (state: RootState) => state.settings.appMode;

export const selectSettingsDeveloper = (state: RootState) => state.settings.dev;

export const selectSettingsFeatures = (state: RootState) =>
  state.settings.features;

export const selectActorConfig = (state: RootState) =>
  state.settings.actorConfig;

export const selectAssistantConfig = (state: RootState) =>
  state.settings.assistantConfig;

export default settingsSlice.reducer;
