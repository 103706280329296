import { useEffect, useMemo, useState } from 'react';
import { Chip } from '@nextui-org/react';
import { motion } from 'framer-motion';
import {
  ChatMessage,
  ChatMessageWithAssistant,
  ChatMessageWithClient,
} from '../../types';
import ChatBubble, { ChatBubbleProps } from './bubble/ChatBubble';
import { useAppSelector } from '../../store/hooks';
import { selectCurrentClient } from '../../store/client/clientSlice';
import {
  riskToLabel,
  selectRiskOfChats,
} from '../../store/assistant/assistantSlice';
import VerticalScrollContainer from '../base/VerticalScrollContainer';
import { MOTION_KEYS } from '../../util';

export interface ChatBoxProps {
  messages: (ChatMessage | ChatMessageWithClient | ChatMessageWithAssistant)[];
  onTransfer?: (message: string, sendImmediately?: boolean) => void;
  bottomIndicator?: JSX.Element;
  emptyIndicator?: JSX.Element;
  isAssistant?: boolean;
  headerTitle?: string;
  headerElements?: JSX.Element[];
  inputField?: JSX.Element;
}

export default function ChatBox({
  messages,
  onTransfer,
  bottomIndicator,
  emptyIndicator,
  isAssistant = false,
  headerTitle,
  headerElements,
  inputField,
}: ChatBoxProps) {
  const currentClient = useAppSelector(selectCurrentClient);
  const riskOfChats = useAppSelector(selectRiskOfChats);
  const [messageCount, setMessageCount] = useState(0);
  const [scrollResetSignal, setScrollResetSignal] = useState(0);
  const [scrollBottomSignal, setScrollBottomSignal] = useState(0);

  const riskLevel = useMemo(
    () =>
      currentClient?.userId ? riskOfChats[currentClient.userId] : undefined,
    [currentClient, riskOfChats]
  );

  const riskLabel = useMemo(
    () => (riskLevel ? riskToLabel(riskLevel) : undefined),
    [riskLevel]
  );

  useEffect(() => {
    /**
     * hides scroll to bottom button on chat switch,
     * so that it doesn't appear in short conversations
     */
    setScrollResetSignal(Date.now());
  }, [currentClient]);

  useEffect(() => {
    const n = messages.length;
    if (n === 0) {
      return;
    }
    //scolls to bottom when there is a new message
    if (messageCount < n) {
      setScrollBottomSignal(Date.now());
      setMessageCount(n);
    }
  }, [messages, messageCount]);

  const chatHeader = (
    <motion.div
      key="chat-header"
      variants={{
        [MOTION_KEYS.INITIAL]: { y: '-150%' },
        [MOTION_KEYS.ANIMATE]: { y: 0 },
      }}
      initial={MOTION_KEYS.INITIAL}
      animate={MOTION_KEYS.ANIMATE}
      exit={MOTION_KEYS.INITIAL}
      className="flex flex-row items-center gap-2 p-2 border-b-1 backdrop-blur-md"
    >
      <div className="flex flex-row flex-1 items-center gap-2 px-2">
        {headerTitle && (
          <p key={headerTitle} className="text-lg font-semibold">
            {headerTitle}
          </p>
        )}
        {isAssistant ||
          (riskLabel && (
            <motion.div
              key={currentClient?.userId}
              variants={{
                [MOTION_KEYS.INITIAL]: { scale: 0 },
                [MOTION_KEYS.ANIMATE]: { scale: riskLabel ? 1 : 0 },
              }}
            >
              <Chip size="sm" color={riskLabel.color} variant="flat">
                {riskLabel.content}
              </Chip>
            </motion.div>
          ))}
      </div>
      {headerElements}
    </motion.div>
  );

  const chatInput = (
    <motion.div
      key="chat-input"
      variants={{
        [MOTION_KEYS.INITIAL]: { y: '150%' },
        [MOTION_KEYS.ANIMATE]: { y: 0 },
      }}
      initial={MOTION_KEYS.INITIAL}
      animate={MOTION_KEYS.ANIMATE}
      exit={MOTION_KEYS.INITIAL}
      className="relative p-2"
    >
      {inputField}
    </motion.div>
  );

  const bubbles =
    messages.length > 0 ? (
      <motion.div
        key="bubbles"
        variants={{
          [MOTION_KEYS.INITIAL]: {},
          [MOTION_KEYS.ANIMATE]: {
            transition: { delayChildren: 0.1, staggerChildren: 0.1 },
          },
          [MOTION_KEYS.EXIT]: {
            transition: { staggerChildren: 0.05, staggerDirection: -1 },
          },
        }}
        initial={MOTION_KEYS.INITIAL}
        animate={MOTION_KEYS.ANIMATE}
        exit={MOTION_KEYS.EXIT}
        className="flex flex-col w-full gap-4"
      >
        {messages.map((m, i) => {
          const isIncoming = m.role === 'assistant';
          const props: ChatBubbleProps = {
            messageId: m.messageId,
            message: m.content,
            timestamp: m.createdAt,
            role: m.role,
            hue: isAssistant ? 'assistant' : 'client',
            showVoteButtons:
              currentClient?.userType === 'client' && isAssistant && isIncoming,
            showRiskIndicator: isIncoming,
            onTransfer,
          };
          if ('promptType' in m) {
            props.promptType = m.promptType;
          }
          if ('modelTag' in m && m.role === 'assistant') {
            props.modelTag = m.modelTag;
          }
          return <ChatBubble key={i} {...props} />;
        })}
      </motion.div>
    ) : (
      emptyIndicator || (
        <Chip size="sm" variant="flat" className="text-gray-600 self-center">
          No messages
        </Chip>
      )
    );

  const bottomIndicatorWrapped = bottomIndicator ? (
    <motion.div
      variants={{
        [MOTION_KEYS.INITIAL]: { opacity: 0 },
        [MOTION_KEYS.ANIMATE]: { opacity: 1 },
      }}
      initial={MOTION_KEYS.INITIAL}
      animate={MOTION_KEYS.ANIMATE}
      exit={MOTION_KEYS.INITIAL}
    >
      {bottomIndicator}
    </motion.div>
  ) : undefined;

  return (
    <VerticalScrollContainer
      topContent={chatHeader}
      bottomContent={chatInput}
      resetSignal={scrollResetSignal}
      scrollToBottomSignal={scrollBottomSignal}
    >
      <div className="flex flex-col w-full pt-4 px-4 gap-4">
        {bubbles}
        {bottomIndicatorWrapped}
      </div>
    </VerticalScrollContainer>
  );
}
