import './App.css';
import { NextUIProvider } from '@nextui-org/react';
import { useAppDispatch, useAppSelector } from './store/hooks';
import {
  pushClientMessage,
  selectWebsocket,
  setConnected,
} from './store/chat/chatSlice';
import { Route, Routes, useLocation } from 'react-router-dom';
import ChatPage from './pages/ChatPage';
import LoginPage from './pages/LoginPage';
import { useEffect } from 'react';
import { setClient } from './store/client/clientSlice';
import { evaluateRiskOfMessage } from './store/assistant/assistantThunks';
import { Slide, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import { WebSocketMessageDto } from './types';
import { selectSettingsDeveloper } from './store/settings/settingsSlice';
import { AnimatePresence } from 'framer-motion';

function App() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const webSocket = useAppSelector(selectWebsocket);
  const settingsDev = useAppSelector(selectSettingsDeveloper);

  useEffect(() => {
    webSocket.onerror = (e) => {
      console.error('WebSocket error', e);
    };
    webSocket.onopen = () => {
      dispatch(setConnected(true));
    };
    webSocket.onclose = () => {
      dispatch(setConnected(false));
    };
    webSocket.onmessage = (e) => {
      try {
        const data: WebSocketMessageDto = JSON.parse(e.data);
        const {
          message_id: messageId,
          chat_id: userId,
          username,
          first_name: friendlyName,
          content: payload,
        } = data;
        if (!messageId || !userId || !payload) {
          return;
        }

        dispatch(
          setClient({ userId, username, friendlyName, userType: 'client' })
        );
        dispatch(
          pushClientMessage({
            messageId,
            userId,
            payload,
          })
        );

        if (settingsDev.autoEvaluateMessageRisk) {
          dispatch(evaluateRiskOfMessage(payload));
        }
      } catch (err) {
        console.error(`unable to parse ${e.data}`, err);
      }
    };
  }, [dispatch, webSocket, settingsDev]);

  return (
    <NextUIProvider>
      <ToastContainer transition={Slide} />
      <AnimatePresence mode="wait">
        <Routes key={location.key} location={location}>
          <Route path="/" element={<ChatPage />} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </AnimatePresence>
    </NextUIProvider>
  );
}

export default App;
