import { toast } from 'react-toastify';
import { ChatMessage } from '../../types';
import { checkResponse, fetchWithAuth } from '../../util';
import { AppThunk } from '../store';
import {
  AssistantPromptDto,
  EvaluateRiskReqDto,
  EvaluateRiskResDto,
} from './assistantDto';
import { addRiskOfChat, addRiskOfMessage } from './assistantSlice';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;
const LIST_ASSISTANT_PROMPTS_URL = new URL('assistant/prompt', BASE_URL);
const EVALUATE_RISK_URL = new URL('assistant/risk', BASE_URL);

export const getAssistantPrompts = async () => {
  const res = await checkResponse(fetchWithAuth(LIST_ASSISTANT_PROMPTS_URL), {
    toastOnApiError: true,
    toastOnFetchError: true,
  });
  if (res) {
    const body: AssistantPromptDto[] = await res.json();
    return body;
  }
};

export const evaluateRiskOfChat =
  (chatId: string, messages: ChatMessage[]): AppThunk =>
  async (dispatch) => {
    const clientMessages = messages
      .filter((m) => m.role === 'assistant')
      .map((m) => m.content.trim());
    if (clientMessages.length === 0) {
      toast.warn('No messages to evaluate on.');
      return;
    }
    const data: EvaluateRiskReqDto = { message: clientMessages.join('\n\n') };
    const res = await checkResponse(
      fetchWithAuth(EVALUATE_RISK_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      }),
      { toastOnApiError: true, toastOnFetchError: true }
    );
    if (res) {
      const body: EvaluateRiskResDto = await res.json();
      dispatch(addRiskOfChat({ key: chatId, risk: body.risk }));
    }
  };

export const evaluateRiskOfMessage =
  (message: string): AppThunk =>
  async (dispatch) => {
    const data: EvaluateRiskReqDto = { message };
    const res = await checkResponse(
      fetchWithAuth(EVALUATE_RISK_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      }),
      { toastOnApiError: true, toastOnFetchError: true }
    );
    if (res) {
      const body: EvaluateRiskResDto = await res.json();
      dispatch(addRiskOfMessage({ key: data.message, risk: body.risk }));
    }
  };
