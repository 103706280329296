import { toast } from 'react-toastify';
import { User, UserAuth, UserRegister } from '../../types';
import { AppThunk } from '../store';
import { clearAuth, setUser } from './authSlice';
import { checkResponse, fetchWithAuth, saveJwt } from '../../util';
import { UserLoginResDto } from './authDto';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;
const SELF_URL = new URL('user/me', BASE_URL);
const LOGIN_URL = new URL('user/login', BASE_URL);
const LOGOUT_URL = new URL('user/logout', BASE_URL);
const REGISTER_URL = new URL('user/register', BASE_URL);

export const register =
  (auth: UserRegister): AppThunk =>
  async () => {
    const res = await checkResponse(
      fetch(REGISTER_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(auth),
      }),
      { toastOnApiError: true, toastOnFetchError: true }
    );
    if (res) {
      toast.success(
        'Successfully registered!\n' +
          'Contact administrator to activate account.',
        { onClose: () => window.location.reload() }
      );
    }
  };

export const login =
  (auth: UserAuth): AppThunk =>
  async (dispatch) => {
    const body = new URLSearchParams();
    body.append('username', auth.username);
    body.append('password', auth.password);
    const loginRes = await checkResponse(
      fetch(LOGIN_URL, {
        method: 'POST',
        body,
        credentials: 'include',
      }),
      { toastOnApiError: true, toastOnFetchErrorMessage: 'Failed to login!' }
    );
    if (!loginRes) {
      return;
    }
    const loginDto: UserLoginResDto = await loginRes.json();
    saveJwt(loginDto.access_token);

    const userRes = await checkResponse(fetchWithAuth(SELF_URL), {
      toastOnApiError: true,
      toastOnFetchErrorMessage: 'Failed to retrieve user info!',
    });
    if (userRes) {
      const user: User = await userRes.json();
      dispatch(setUser(user));
      toast.success('Successfully logged in!');
    }
  };

export const logout = (): AppThunk => async (dispatch) => {
  if (
    await checkResponse(fetchWithAuth(LOGOUT_URL, { method: 'POST' }), {
      toastOnApiError: true,
      toastOnFetchErrorMessage: 'Failed to logout',
    })
  ) {
    dispatch(clearAuth());
    toast.info('Successfully logged out!');
  }
};

export const getSelf = (): AppThunk => async (dispatch) => {
  const res = await checkResponse(fetchWithAuth(SELF_URL));
  if (res) {
    const user: User = await res.json();
    dispatch(setUser(user));
  }
};
