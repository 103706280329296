import { Button, useDisclosure } from '@nextui-org/react';
import PromptModal from './PromptModal';

export default function PromptButton() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <div>
      <Button color="primary" onPress={onOpen}>
        View prompts
      </Button>
      <PromptModal isOpen={isOpen} onOpenChange={onOpenChange} />
    </div>
  );
}
