import { Button, ButtonGroup, Chip, Tooltip } from '@nextui-org/react';
import { useMemo } from 'react';
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa6';
import { motion, useAnimate } from 'framer-motion';
import { AppHue, MessageRole, PromptType } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  riskToLabel,
  selectRiskOfMessages,
} from '../../../store/assistant/assistantSlice';
import { selectAssistantMessageScore } from '../../../store/chat/chatSlice';
import { setAssistantMessageScoreThunk } from '../../../store/chat/chatThunks';
import { AssistantMessageScoreDto } from '../../../store/chat/chatDto';
import ChatBubbleSingle, { ChatBubbleSingleProps } from './ChatBubbleSingle';
import ChatBubbleMultiple from './ChatBubbleMultiple';
import {
  formatIsoToLocaleDateTime,
  MOTION_KEYS,
  quoteMessage,
} from '../../../util';
import { selectSettingsDeveloper } from '../../../store/settings/settingsSlice';

const SCORE_UP = 1;
const SCORE_DOWN = -1;

export interface ChatBubbleProps {
  messageId: string;
  message: string;
  role: MessageRole;
  hue?: AppHue;
  modelTag?: string;
  timestamp?: string;
  promptType?: PromptType;
  showVoteButtons?: boolean;
  showRiskIndicator?: boolean;
  onTransfer?: (message: string, sendImmediately?: boolean) => void;
}

export default function ChatBubble({
  messageId,
  message,
  role,
  hue,
  modelTag,
  timestamp,
  promptType = 'direct',
  showVoteButtons,
  showRiskIndicator,
  onTransfer,
}: ChatBubbleProps) {
  const dispatch = useAppDispatch();
  const settingsDev = useAppSelector(selectSettingsDeveloper);
  const riskOfMessages = useAppSelector(selectRiskOfMessages);
  const score = useAppSelector(selectAssistantMessageScore(messageId));
  const [scopeThumbsUp, animateThumbsUp] = useAnimate<HTMLDivElement>();
  const [scopeThumbsDown, animateThumbsDown] = useAnimate<HTMLDivElement>();

  const handleScoreChange = (newScore: number) => () => {
    const dto: AssistantMessageScoreDto = {
      message_assistant_id: messageId,
      score: newScore,
    };
    if (score === newScore) {
      dto.score = 0;
    } else {
      switch (newScore) {
        case SCORE_UP:
          animateThumbsUp(scopeThumbsUp.current, {
            y: [0, 2, -10, 2, 0],
            rotate: [0, 0, 180, 360, 360],
          });
          break;
        case SCORE_DOWN:
          animateThumbsDown(scopeThumbsDown.current, {
            y: [0, -2, 10, -2, 0],
            rotate: [0, 0, 30, -15, 0],
          });
          break;
      }
    }
    dispatch(setAssistantMessageScoreThunk(dto));
  };

  const quotedMessage = useMemo(
    () => quoteMessage(message, promptType),
    [message, promptType]
  );

  const riskLabel = useMemo(() => {
    if (riskOfMessages[message]) {
      return riskToLabel(riskOfMessages[message]);
    }
  }, [message, riskOfMessages]);

  const riskIndicator = useMemo(
    () =>
      showRiskIndicator && riskLabel ? (
        <Tooltip showArrow content={riskLabel.content} color={riskLabel.color}>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            whileHover={{ scale: 1.2 }}
            className={
              'w-4 h-4 rounded-full shadow-small border-2 ' +
              `${
                (riskLabel.color === 'default' &&
                  'bg-neutral-100 border-neutral-300') ||
                (riskLabel.color === 'danger' &&
                  'bg-danger-500 border-danger-100') ||
                (riskLabel.color === 'warning' &&
                  'bg-warning-500 border-warning-100') ||
                (riskLabel.color === 'success' &&
                  'bg-success-500 border-success-100')
              }`
            }
          />
        </Tooltip>
      ) : undefined,
    [showRiskIndicator, riskLabel]
  );

  const timestampChip = useMemo(() => {
    if (!timestamp) {
      return;
    }
    const formatted = formatIsoToLocaleDateTime(timestamp);
    if (!formatted) {
      return;
    }
    return (
      <Chip size="sm" variant="flat" className="text-gray-700 italic">
        {formatted}
      </Chip>
    );
  }, [timestamp]);

  const modelTagChip = useMemo(
    () =>
      modelTag && settingsDev.showMessageModelTag ? (
        <Chip
          size="sm"
          variant="flat"
          className="text-gray-700 italic bg-gradient-to-br from-blue-100 to-red-100"
        >
          {modelTag}
        </Chip>
      ) : undefined,
    [modelTag, settingsDev]
  );

  const upDownVoteButtons = showVoteButtons ? (
    <ButtonGroup isIconOnly size="sm" radius="full" variant="flat">
      <Button
        color={score === SCORE_UP ? 'primary' : 'default'}
        onPress={handleScoreChange(SCORE_UP)}
      >
        <div ref={scopeThumbsUp}>
          <FaThumbsUp />
        </div>
      </Button>
      <Button
        color={score === SCORE_DOWN ? 'primary' : 'default'}
        onPress={handleScoreChange(SCORE_DOWN)}
      >
        <div ref={scopeThumbsDown}>
          <FaThumbsDown />
        </div>
      </Button>
    </ButtonGroup>
  ) : undefined;

  const messageBubble = useMemo(() => {
    const props: ChatBubbleSingleProps = {
      message,
      role,
      onTransfer,
      showTransfer: true,
      showEvaluateRisk: role === 'assistant' && hue === 'client',
    };
    switch (role) {
      case 'assistant':
        switch (hue) {
          case 'assistant':
            return <ChatBubbleMultiple {...props} />;
          case 'client':
            return <ChatBubbleSingle {...props} />;
        }
        break;
      case 'user':
        props.hue = hue;
        if (hue === 'assistant') {
          props.message = quotedMessage;
        }
        return <ChatBubbleSingle {...props} />;
    }
  }, [message, quotedMessage, role, hue, onTransfer]);

  return (
    <motion.div
      variants={{
        [MOTION_KEYS.ANIMATE]: { opacity: [0, 1] },
        [MOTION_KEYS.EXIT]: { opacity: 0 },
      }}
      className={`relative max-w-[calc(100%-2rem)] flex flex-col gap-2 ${
        role === 'assistant'
          ? 'items-start self-start mr-4'
          : 'items-end self-end ml-4'
      }`}
    >
      <motion.div key={messageId} layoutId={messageId} layout="position">
        {messageBubble}
      </motion.div>
      <div
        className={`flex items-center gap-2 ${
          role === 'assistant' ? 'flex-row' : 'flex-row-reverse'
        }`}
      >
        {timestampChip}
        {modelTagChip}
        {upDownVoteButtons}
        {riskIndicator}
      </div>
    </motion.div>
  );
}
