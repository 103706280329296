import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Skeleton,
  useDisclosure,
} from '@nextui-org/react';
import { useAppDispatch } from '../../../store/hooks';
import { evaluateRiskOfMessage } from '../../../store/assistant/assistantThunks';
import { Key, useMemo } from 'react';
import {
  FaCopy,
  FaEllipsis,
  FaPen,
  FaShare,
  FaTrafficLight,
} from 'react-icons/fa6';
import { useAnimate } from 'framer-motion';
import { AppHue, MessageRole } from '../../../types';
import MarkdownWrapper from '../../base/MarkdownWrapper';

const KEY_COPY_CLIP = 'copy_clip';
const KEY_COPY_EDIT = 'copy_edit';
const KEY_COPY_SEND = 'copy_send';
const KEY_EVAL_RISK = 'eval_risk';

export interface ChatBubbleSingleProps {
  message: string;
  role: MessageRole;
  hue?: AppHue;
  onTransfer?: (message: string, sendImmediately?: boolean) => void;
  showTransfer?: boolean;
  showEvaluateRisk?: boolean;
}

export default function ChatBubbleSingle({
  message,
  role,
  hue,
  onTransfer,
  showTransfer,
  showEvaluateRisk,
}: ChatBubbleSingleProps) {
  const dispatch = useAppDispatch();
  const {
    isOpen: isSendModalOpen,
    onOpen: onSendModalOpen,
    onOpenChange: onSendModalOpenChange,
  } = useDisclosure();
  const [scope, animate] = useAnimate<HTMLDivElement>();

  const hasClipboard = useMemo(
    () => navigator.clipboard && window.isSecureContext,
    []
  );

  const animateTransfer = () => {
    animate(scope.current, {
      y: [0, -8, 2, 0],
    });
  };

  const handleEvaluateRisk = () => {
    dispatch(evaluateRiskOfMessage(message));
  };

  const handleCopy = () => {
    if (hasClipboard) {
      navigator.clipboard.writeText(message);
    }
  };

  const handleTransfer = () => {
    if (onTransfer) {
      onTransfer(message, false);
      animateTransfer();
    }
  };

  const handleTransferAndSend = () => {
    if (onTransfer) {
      onTransfer(message, true);
      animateTransfer();
    }
  };

  const handleSendModalClose = (onClose: () => void) => () => {
    onClose();
    handleTransferAndSend();
  };

  const handleDropdownAction = (key: Key) => {
    switch (key) {
      case KEY_COPY_CLIP:
        handleCopy();
        break;
      case KEY_COPY_EDIT:
        handleTransfer();
        break;
      case KEY_COPY_SEND:
        onSendModalOpen();
        break;
      case KEY_EVAL_RISK:
        handleEvaluateRisk();
        break;
    }
  };

  // `_Loading..._` is a placeholder so that the skeleton can be seen
  const markdownMessage = (
    <MarkdownWrapper>{message || '_Loading..._'}</MarkdownWrapper>
  );

  const dropdownSections = useMemo(() => {
    const sections: JSX.Element[] = [];

    if (showEvaluateRisk) {
      sections.push(
        <DropdownSection key="evaluate" title="Evaluate" showDivider>
          <DropdownItem key={KEY_EVAL_RISK} startContent={<FaTrafficLight />}>
            Evaluate suicidal risk
          </DropdownItem>
        </DropdownSection>
      );
    }

    const copyItems: JSX.Element[] = [];
    if (hasClipboard) {
      copyItems.push(
        <DropdownItem key={KEY_COPY_CLIP} startContent={<FaCopy />}>
          Copy to clipboard
        </DropdownItem>
      );
    }
    if (showTransfer && onTransfer) {
      copyItems.push(
        <DropdownItem key={KEY_COPY_EDIT} startContent={<FaPen />}>
          Copy and edit
        </DropdownItem>
      );
      copyItems.push(
        <DropdownItem
          key={KEY_COPY_SEND}
          startContent={<FaShare />}
          color="danger"
          className="text-danger"
        >
          Copy and send
        </DropdownItem>
      );
    }
    if (copyItems.length > 0) {
      sections.push(
        <DropdownSection key="copy" title="Copy">
          {copyItems}
        </DropdownSection>
      );
    }
    return sections;
  }, [hasClipboard, showEvaluateRisk, showTransfer, onTransfer]);

  const moreButton = (
    <>
      <Dropdown>
        <DropdownTrigger>
          <Button
            isIconOnly
            size="sm"
            color="primary"
            radius="full"
            variant="shadow"
          >
            <FaEllipsis />
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          aria-label="Message actions"
          variant="flat"
          onAction={handleDropdownAction}
          emptyContent={<p className="text-xs italic">No actions.</p>}
        >
          {dropdownSections}
        </DropdownMenu>
      </Dropdown>
      <Modal
        isOpen={isSendModalOpen}
        onOpenChange={onSendModalOpenChange}
        size="3xl"
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Copy and send this message?</ModalHeader>
              <ModalBody>{markdownMessage}</ModalBody>
              <ModalFooter>
                <ButtonGroup variant="flat">
                  <Button onPress={onClose}>Cancel</Button>
                  <Button
                    onPress={handleSendModalClose(onClose)}
                    color="danger"
                  >
                    Copy and send
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );

  return (
    <div ref={scope} className="group relative">
      <Card
        shadow="none"
        className={`${
          hue === 'assistant' &&
          'bg-gradient-to-br from-orange-100 to-red-100 border-red-200'
        } ${
          hue === 'client' &&
          'bg-gradient-to-br from-blue-100 to-purple-100 border-purple-200'
        } border-1`}
      >
        <CardBody>
          <Skeleton isLoaded={!!message} className="rounded-lg">
            {markdownMessage}
          </Skeleton>
        </CardBody>
      </Card>
      <div
        className={`absolute bottom-2 translate-y-1/2 z-10 ${
          role === 'assistant'
            ? 'end-2 translate-x-1/2'
            : 'start-2 -translate-x-1/2'
        } transition-all scale-0 group-hover:scale-100`}
      >
        {moreButton}
      </div>
    </div>
  );
}
