export interface ChatInputPlaceholderProps {
  text: string;
}

/**
 * Simple placeholder for chat input that displays a message to the user.
 */
export default function ChatInputPlaceholder({
  text,
}: ChatInputPlaceholderProps) {
  return (
    <div className="relative flex w-full border-1 px-3 py-2 rounded-2xl border-white/30 backdrop-blur-md shadow-small">
      <p className="text-small text-gray-600 italic">{text}</p>
    </div>
  );
}
