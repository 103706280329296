import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { LayoutGroup, motion } from 'framer-motion';
import ChatView from '../chat/ChatView';
import ChatInputBase from '../chat/input/ChatInputBase';
import FeatureBar from '../feature/FeatureBar';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectSettingsFeatures } from '../../store/settings/settingsSlice';
import { PromptType } from '../../types';
import FeatureItem from '../feature/FeatureItem';
import {
  appendLetsTalkInputWithAssistant,
  clearMessagesWithLetsTalkAssistant,
  clearMessagesWithLetsTalkClient,
  exportMessagesPlainText,
  pushLetsTalkClientMessage,
  pushLetsTalkUserToAssistantMessage,
  pushLetsTalkUserToClientMessage,
  selectLetsTalkInputWithAssistant,
  selectLetsTalkInputWithClient,
  selectLetsTalkMessagesWithAssistant,
  selectLetsTalkMessagesWithClient,
  setLetsTalkInputWithAssistant,
  setLetsTalkInputWithClient,
} from '../../store/chat/chatSlice';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Tooltip,
} from '@nextui-org/react';
import ChatBubbleSingle, {
  ChatBubbleSingleProps,
} from '../chat/bubble/ChatBubbleSingle';
import {
  directChatForLetsTalk,
  generateResourceForLetsTalk,
  generateResponseForLetsTalk,
  rewriteEmpatheticallyForLetsTalk,
} from '../../store/chat/chatThunks';
import VerticalScrollContainer from '../base/VerticalScrollContainer';
import { FaFileArrowDown, FaTrash } from 'react-icons/fa6';
import ButtonWithConfirmModal from '../base/ButtonWithConfirmModal';
import ChatBubbleMultiple from '../chat/bubble/ChatBubbleMultiple';

export default function LetsTalkView() {
  const dispatch = useAppDispatch();
  const features = useAppSelector(selectSettingsFeatures);
  const messagesWithAssistant = useAppSelector(
    selectLetsTalkMessagesWithAssistant
  );
  const messagesWithClient = useAppSelector(selectLetsTalkMessagesWithClient);
  const inputWithAssistant = useAppSelector(selectLetsTalkInputWithAssistant);
  const inputWithClient = useAppSelector(selectLetsTalkInputWithClient);
  const [scrollToBottomSignal, setScrollToBottomSignal] = useState(0);
  const [assistantTextKey, setAssistantTextKey] = useState(uuidv4());
  const [clientTextKey, setClientTextKey] = useState(uuidv4());

  useEffect(() => {
    setScrollToBottomSignal(Date.now());
  }, [messagesWithClient]);

  // whether input with client should come before input with assistant;
  // used with `flex-col` and `flex-col-reverse` below
  const inputFlexOrder = useMemo(
    () =>
      messagesWithClient.length === 0 ||
      messagesWithClient[messagesWithClient.length - 1].role === 'assistant',
    [messagesWithClient]
  );

  const cycleAssistantTextKey = () => {
    const key = assistantTextKey;
    setAssistantTextKey(uuidv4());
    return key;
  };

  const cycleClientTextKey = () => {
    const key = clientTextKey;
    setClientTextKey(uuidv4());
    return key;
  };

  const handleAssistantInputChange = (payload: string) => {
    dispatch(setLetsTalkInputWithAssistant(payload));
  };

  const handleAssistantInputSubmit = (value: string) => {
    const payload = value.trim();
    dispatch(
      pushLetsTalkUserToAssistantMessage({
        userId: '', // ignored
        messageId: cycleAssistantTextKey(),
        payload,
      })
    );

    dispatch(directChatForLetsTalk(payload));
  };

  const handleAssistantInputPublish = useCallback(
    (payload: string, textKey: string) => {
      dispatch(
        pushLetsTalkUserToClientMessage({
          userId: '', // ignored
          messageId: textKey,
          payload,
        })
      );
      dispatch(setLetsTalkInputWithAssistant(''));
      dispatch(clearMessagesWithLetsTalkAssistant());
    },
    [dispatch]
  );

  const handleClientInputChange = (payload: string) => {
    dispatch(setLetsTalkInputWithClient(payload));
  };

  const handleClientInputSubmit = (payload: string) => {
    dispatch(
      pushLetsTalkClientMessage({
        userId: '', // ignored
        messageId: cycleClientTextKey(),
        payload,
      })
    );
  };

  const handleExportPlainText = useCallback(() => {
    exportMessagesPlainText(messagesWithClient, {
      messagesLabel: `Let's Talk ${new Date().toISOString()}`,
      assistantLabel: 'Affected person',
      userLabel: 'Therapist',
    });
  }, [messagesWithClient]);

  const handleClear = useCallback(() => {
    dispatch(clearMessagesWithLetsTalkAssistant());
    dispatch(clearMessagesWithLetsTalkClient());
  }, [dispatch]);

  const featureFunctions = useMemo(() => {
    const functions = new Map<PromptType, () => void>();
    functions.set('generate_response', () =>
      dispatch(generateResponseForLetsTalk(false))
    );
    functions.set('generate_forum_response', () =>
      dispatch(generateResponseForLetsTalk(true))
    );
    functions.set('recommend_resource', () =>
      dispatch(generateResourceForLetsTalk())
    );
    functions.set('empathetic_rewrite', () =>
      dispatch(rewriteEmpatheticallyForLetsTalk())
    );
    return functions;
  }, [dispatch]);

  const actionButtons = useMemo(
    () => (
      <ButtonGroup
        isIconOnly
        radius="full"
        variant="flat"
        className="p-2 self-center"
      >
        <Tooltip content="Export as plain text" placement="bottom">
          <Button onPress={handleExportPlainText} className="backdrop-blur-md">
            <FaFileArrowDown />
          </Button>
        </Tooltip>
        <ButtonWithConfirmModal
          onPress={handleClear}
          buttonContent={<FaTrash />}
          buttonProps={{ color: 'danger', className: 'backdrop-blur-md' }}
          tooltipProps={{
            content: 'Clear conversation',
            placement: 'bottom',
            color: 'danger',
          }}
          confirmLabel="Clear"
          modalHeader="Clear conversation?"
        />
      </ButtonGroup>
    ),
    [handleClear, handleExportPlainText]
  );

  const featurePublish = (
    <FeatureItem
      key="publish"
      feature={{
        key: 'direct',
        label: 'Publish message',
        description:
          'Publishes the draft to the conversation with the affected person and ' +
          'clears the conversation with the assistant',
        isEnabled: true,
        isAutoRun: false,
      }}
      onPress={() =>
        handleAssistantInputPublish(inputWithAssistant, cycleAssistantTextKey())
      }
      buttonProps={{
        className: 'bg-gradient-to-br from-blue-100 to-purple-100',
        isDisabled: inputWithAssistant.length === 0,
      }}
    />
  );

  const featureBar = (
    <FeatureBar>
      {featurePublish}
      {features
        .filter((feat) => featureFunctions.has(feat.key))
        .map(
          (feat) =>
            feat.isEnabled && (
              <FeatureItem
                key={feat.key}
                feature={feat}
                onPress={featureFunctions.get(feat.key)}
              />
            )
        )}
    </FeatureBar>
  );

  const bubblesClient = useMemo(
    () =>
      messagesWithClient.length > 0 ? (
        messagesWithClient.map((m) => {
          const props: ChatBubbleSingleProps = {
            message: m.content,
            role: m.role,
            hue: m.role === 'user' ? 'client' : undefined,
          };
          return (
            <motion.div
              key={m.messageId}
              layoutId={m.messageId}
              layout="position"
              animate={{ opacity: [0, 1] }}
              className={`max-w-[calc(100%-2rem)] ${
                m.role === 'assistant' ? 'self-start mr-4' : 'self-end ml-4'
              }`}
            >
              <ChatBubbleSingle key={m.messageId} {...props} />
            </motion.div>
          );
        })
      ) : (
        <motion.div
          key="chip-no-messages"
          layout="position"
          className="self-center"
        >
          <Chip size="sm" variant="flat" className="text-gray-600">
            No messages
          </Chip>
        </motion.div>
      ),
    [messagesWithClient]
  );

  const bubblesAssistant = useMemo(
    () =>
      messagesWithAssistant.map((m) => {
        const props: ChatBubbleSingleProps = {
          message: m.content,
          role: m.role,
          hue: m.role === 'user' ? 'assistant' : undefined,
          onTransfer(message, sendImmediately) {
            if (sendImmediately) {
              handleAssistantInputPublish(message, m.messageId);
            } else {
              dispatch(
                appendLetsTalkInputWithAssistant(`\n\n${message.trim()}`)
              );
            }
          },
          showTransfer: true,
        };
        return (
          <motion.div
            key={m.messageId}
            layoutId={m.messageId}
            layout="position"
            animate={{ opacity: [0, 1] }}
            className={`max-w-[calc(100%-2rem)] ${
              m.role === 'assistant' ? 'self-start mr-4' : 'self-end ml-4'
            }`}
          >
            <ChatBubbleMultiple key={m.messageId} {...props} />
          </motion.div>
        );
      }),
    [dispatch, handleAssistantInputPublish, messagesWithAssistant]
  );

  const inputLeft = (
    <motion.div
      key="input-left"
      layout="position"
      className="relative self-start w-3/4"
    >
      <ChatInputBase
        textKey={clientTextKey}
        textValue={inputWithClient}
        onTextValueChange={handleClientInputChange}
        onTextValueSubmit={handleClientInputSubmit}
        textAreaProps={{ placeholder: 'Post from affected person' }}
      />
    </motion.div>
  );

  const inputRight = (
    <motion.div key="input-right" layout="position" className="self-end w-3/4">
      <Card shadow="none" className="border-1">
        <CardHeader>
          <p className="text-small text-gray-600 italic">
            Response creation assistant
          </p>
        </CardHeader>
        <CardBody className="relative flex flex-col gap-2 overflow-visible">
          {bubblesAssistant}
        </CardBody>
        <CardFooter className="relative flex flex-col items-end gap-2">
          {featureBar}
          <ChatInputBase
            textKey={assistantTextKey}
            textValue={inputWithAssistant}
            onTextValueChange={handleAssistantInputChange}
            onTextValueSubmit={handleAssistantInputSubmit}
            submitButtonProps={{
              className: 'bg-gradient-to-br from-orange-500 to-red-500',
            }}
            textAreaProps={{ placeholder: 'Write a post' }}
          />
        </CardFooter>
      </Card>
    </motion.div>
  );

  const chatBox = (
    <VerticalScrollContainer
      topContent={actionButtons}
      scrollToBottomSignal={scrollToBottomSignal}
    >
      <LayoutGroup id="lets-talk-layout-group">
        {/* flex-col-reverse so that bubbles are
        on top of inputs during transitions */}
        <div className="flex flex-col-reverse w-full p-4 gap-4">
          <div
            className={`flex gap-4 ${
              inputFlexOrder ? 'flex-col' : 'flex-col-reverse'
            }`}
          >
            {inputLeft}
            {inputRight}
          </div>
          <motion.div
            key="bubbles-client"
            layout
            className="flex flex-col w-full gap-4"
          >
            {bubblesClient}
          </motion.div>
        </div>
      </LayoutGroup>
    </VerticalScrollContainer>
  );

  return <ChatView chatId="lets-talk" chatBox={chatBox} />;
}
