import {
  Button,
  ButtonGroup,
  ButtonProps,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tooltip,
  TooltipProps,
  useDisclosure,
} from '@nextui-org/react';

export interface ButtonWithConfirmModalProps {
  onPress?: () => void;
  buttonContent: JSX.Element | string;
  buttonProps?: ButtonProps;
  tooltipProps?: TooltipProps;
  cancelLabel?: string;
  confirmLabel?: string;
  modalHeader?: JSX.Element | string;
  modalBody?: JSX.Element | string;
}

export default function ButtonWithConfirmModal({
  onPress,
  buttonContent,
  buttonProps,
  tooltipProps,
  cancelLabel,
  confirmLabel,
  modalHeader,
  modalBody,
}: ButtonWithConfirmModalProps) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const handleClose = (onClose: () => void) => () => {
    if (onPress) {
      onPress();
    }
    onClose();
  };

  const buttonOpen = (
    <Button {...buttonProps} onPress={onOpen}>
      {buttonContent}
    </Button>
  );

  return (
    <>
      {tooltipProps ? (
        <Tooltip {...tooltipProps}>{buttonOpen}</Tooltip>
      ) : (
        buttonOpen
      )}
      <Modal backdrop="blur" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              {modalHeader && <ModalHeader>{modalHeader}</ModalHeader>}
              {modalBody && <ModalBody>{modalBody}</ModalBody>}
              <ModalFooter>
                <ButtonGroup variant="flat">
                  <Button onPress={onClose}>{cancelLabel || 'Cancel'}</Button>
                  <Button
                    onPress={handleClose(onClose)}
                    color={buttonProps?.color || 'primary'}
                  >
                    {confirmLabel || 'Ok'}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
