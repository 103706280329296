import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import { useEffect, useState } from 'react';
import MarkdownWrapper from '../base/MarkdownWrapper';

export interface AboutModalProps {
  isOpen: boolean;
  onOpenChange: () => void;
}

export default function AboutModal({ isOpen, onOpenChange }: AboutModalProps) {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + '/about.md')
      .then((res) => res.text())
      .then(setMarkdown);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size="3xl"
      backdrop="blur"
      scrollBehavior="inside"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader>About</ModalHeader>
            <ModalBody>
              <MarkdownWrapper>{markdown}</MarkdownWrapper>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onPress={onClose}>
                Close
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
