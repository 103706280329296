import {
  Button,
  Card,
  CardBody,
  CardFooter,
  colors,
  Input,
  Tab,
  Tabs,
} from '@nextui-org/react';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectLoggedIn } from '../store/auth/authSlice';
import { getSelf, login, register } from '../store/auth/authThunks';
import { MOTION_KEYS } from '../util';

const KEY_LOGIN = 'login';
const KEY_REGISTER = 'register';

/**
 * Generates a series of rotating color gradients.
 *
 * @param colors Colors to animate between (at least 2).
 * @param deg Degrees to rotate to.
 * @returns Array of `linear-gradient` values.
 */
function generateGradients(colors: string[], deg = 360) {
  const n = colors.length;
  const interval = deg / n;
  const gradients: string[] = [];
  for (let i = 0; i < n + 1; i += 1) {
    gradients.push(
      `linear-gradient(${i * interval}deg, ${colors[i % n]}, ${
        colors[(i + 1) % n]
      })`
    );
  }
  return gradients;
}

export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectLoggedIn);
  const [selectedOption, setSelectedOption] = useState<string>('');

  useEffect(() => {
    dispatch(getSelf());
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [navigate, isLoggedIn]);

  const handleLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = new FormData(e.currentTarget);
    const name = form.get('name')?.toString();
    const username = form.get('username')?.toString();
    const password = form.get('password')?.toString();

    switch (selectedOption) {
      case KEY_LOGIN:
        if (username && password) {
          dispatch(login({ username, password }));
        }
        break;
      case KEY_REGISTER:
        if (name && username && password) {
          dispatch(register({ name, username, password }));
        }
        break;
    }
  };

  return (
    <motion.div
      variants={{
        [MOTION_KEYS.INITIAL]: {},
        [MOTION_KEYS.ANIMATE]: {
          background: generateGradients([
            colors.red[200],
            colors.yellow[200],
            colors.green[200],
            colors.cyan[200],
            colors.blue[200],
            colors.purple[200],
          ]),
          transition: { duration: 60, repeat: Infinity, ease: 'linear' },
        },
        [MOTION_KEYS.EXIT]: {},
      }}
      initial={MOTION_KEYS.INITIAL}
      animate={MOTION_KEYS.ANIMATE}
      exit={MOTION_KEYS.EXIT}
      className="flex flex-col justify-center items-center w-screen h-screen"
    >
      <motion.div
        variants={{
          [MOTION_KEYS.ANIMATE]: { scale: 1 },
          [MOTION_KEYS.EXIT]: { scale: 4 },
        }}
        transition={{ delay: 0.5 }}
        className="w-5/6 max-w-lg h-full"
      >
        <Card
          radius="none"
          className="w-full h-full flex flex-col justify-center"
        >
          <motion.form
            variants={{
              [MOTION_KEYS.ANIMATE]: { opacity: 1 },
              [MOTION_KEYS.EXIT]: { opacity: 0 },
            }}
            onSubmit={handleLogin}
          >
            <CardBody className="flex flex-1 flex-col mx-auto gap-4 max-w-md">
              <Tabs
                color="primary"
                selectedKey={selectedOption}
                onSelectionChange={(k) => setSelectedOption(k.toString())}
                aria-label="Login options"
                className="self-center"
              >
                <Tab key={KEY_LOGIN} title="Login" />
                <Tab key={KEY_REGISTER} title="Register" />
              </Tabs>
              {selectedOption === KEY_REGISTER && (
                <Input isRequired name="name" type="text" placeholder="Name" />
              )}
              <Input
                autoFocus
                isRequired
                name="username"
                type="username"
                placeholder="Username"
              />
              <Input
                isRequired
                name="password"
                type="password"
                placeholder="Password"
              />
            </CardBody>
            <CardFooter className="flex flex-row justify-center">
              <Button type="submit" color="primary">
                {selectedOption === KEY_REGISTER ? 'Register' : 'Login'}
              </Button>
            </CardFooter>
          </motion.form>
        </Card>
      </motion.div>
    </motion.div>
  );
}
