import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  pushUserToClientMessage,
  pushUserToWebsocketMessage,
  selectCurrentChatInput,
  setInputWithClient,
} from '../../../store/chat/chatSlice';
import { selectCurrentClient } from '../../../store/client/clientSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ChatInputBase from './ChatInputBase';
import ChatInputPlaceholder from './ChatInputPlaceholder';

export default function ChatInputClient() {
  const dispatch = useAppDispatch();
  const currentClient = useAppSelector(selectCurrentClient);
  const currentChatInput = useAppSelector(selectCurrentChatInput);
  const [textKey, setTextKey] = useState(uuidv4());

  const textValue = useMemo(
    () => currentChatInput?.inputWithClient || '',
    [currentChatInput]
  );

  const cycleTextKey = () => {
    const key = textKey;
    setTextKey(uuidv4());
    return key;
  };

  const handleInputChange = (payload: string) => {
    const userId = currentClient?.userId;
    if (userId) {
      dispatch(setInputWithClient({ userId, payload }));
    }
  };

  const handleInputSubmit = (payload: string) => {
    const userId = currentClient?.userId;
    if (!userId) {
      return;
    }
    dispatch(
      pushUserToClientMessage({ userId, payload, messageId: cycleTextKey() })
    );
    if (currentClient.userType === 'client') {
      dispatch(pushUserToWebsocketMessage({ userId, payload }));
    }
  };

  return currentClient?.isArchived ? (
    <ChatInputPlaceholder text="Chat archived." />
  ) : (
    <ChatInputBase
      textKey={textKey}
      textValue={textValue}
      onTextValueChange={handleInputChange}
      onTextValueSubmit={handleInputSubmit}
      submitButtonProps={{
        className: 'bg-gradient-to-br from-blue-500 to-purple-500',
      }}
      textAreaProps={{ placeholder: 'Message affected person' }}
    />
  );
}
