import { Key } from 'react';
import {
  Button,
  ButtonGroup,
  Chip,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Tab,
  Tabs,
  Tooltip,
  useDisclosure,
} from '@nextui-org/react';
import AboutButton from '../about/AboutButton';
import SettingsButton from '../settings/SettingsButton';
import AboutModal from '../about/AboutModal';
import SettingsModal from '../settings/SettingsModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectDisplayName } from '../../store/auth/authSlice';
import { refreshWebsocket, selectConnected } from '../../store/chat/chatSlice';
import { FaCircleUser, FaRotateRight } from 'react-icons/fa6';
import { logout } from '../../store/auth/authThunks';
import ButtonWithConfirmModal from './ButtonWithConfirmModal';
import { selectAppMode, setAppMode } from '../../store/settings/settingsSlice';
import { APP } from '../../util';

export default function NavBar() {
  const dispatch = useAppDispatch();
  const displayName = useAppSelector(selectDisplayName);
  const isConnected = useAppSelector(selectConnected);
  const appMode = useAppSelector(selectAppMode);
  const {
    isOpen: isAboutOpen,
    onOpen: onAboutOpen,
    onOpenChange: onAboutOpenChange,
  } = useDisclosure();
  const {
    isOpen: isSettingsOpen,
    onOpen: onSettingsOpen,
    onOpenChange: onSettingsOpenChange,
  } = useDisclosure();

  const handleRefresh = () => {
    dispatch(refreshWebsocket());
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleSetAppMode = (key: Key) => {
    // @ts-ignore: key is always of type AppMode
    dispatch(setAppMode(key));
  };

  const connectionIndicator = isConnected ? (
    <Chip color="success" variant="flat">
      Online
    </Chip>
  ) : (
    <Tooltip
      content={
        <Button
          size="sm"
          variant="light"
          startContent={<FaRotateRight />}
          onPress={handleRefresh}
        >
          Reconnect
        </Button>
      }
      className="p-0 overflow-hidden"
    >
      <Chip color="danger" variant="flat">
        Offline
      </Chip>
    </Tooltip>
  );

  const userChip = displayName ? (
    <Chip
      color="primary"
      variant="flat"
      startContent={<FaCircleUser size={18} />}
    >
      {displayName}
    </Chip>
  ) : undefined;

  const modeTabs = (
    <Tabs
      aria-label="App mode"
      radius="full"
      color="primary"
      selectedKey={appMode}
      onSelectionChange={handleSetAppMode}
      classNames={{ tabList: 'shadow-small' }}
    >
      <Tab key="dialogue" title={APP.TITLE.SHORT} />
      <Tab key="letstalk" title="Let's Talk" />
    </Tabs>
  );

  const logoutButton = (
    <ButtonWithConfirmModal
      onPress={handleLogout}
      buttonContent="Logout"
      confirmLabel="Logout"
      modalHeader="Logout?"
    />
  );

  return (
    <Navbar>
      <NavbarBrand className="flex flex-row gap-2">
        <p className="text-lg font-bold">{APP.TITLE.DEFAULT}</p>
        <NavbarItem>{connectionIndicator}</NavbarItem>
      </NavbarBrand>
      <NavbarContent justify="end">
        {userChip && <NavbarItem>{userChip}</NavbarItem>}
        <NavbarItem>{modeTabs}</NavbarItem>
        <NavbarItem>
          <ButtonGroup color="primary" radius="full" variant="flat">
            <AboutButton onPress={onAboutOpen} />
            <SettingsButton onPress={onSettingsOpen} />
            {logoutButton}
          </ButtonGroup>
          <AboutModal isOpen={isAboutOpen} onOpenChange={onAboutOpenChange} />
          <SettingsModal
            isOpen={isSettingsOpen}
            onOpenChange={onSettingsOpenChange}
          />
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
}
